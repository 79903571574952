import { Tab } from "@headlessui/react";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
    className?: string;
    compact?: boolean;
    flair?: {
        label: string;
        className: string;
    };
}

export const Card = (props: Props) => {
    return (
        <div
            className={`
                mt-6 p-5 rounded-xl
                bg-white text-xl drop-shadow-md
                ${props.compact ? "w-fit" : "w-full"}
                ${props.className || ""}
            `}
        >
            {props.children}
        </div>
    );
};

export const DashboardCard = (props: Props) => {
    return (
        <div
            className={`
                mt-2 relative rounded bg-white
                border border-gray-300
                ${props.compact ? "w-fit" : "w-full"}
                ${props.className}
            `}
        >
            {props.flair && (
                <>
                    <svg
                        className="absolute -ml-[8px] mt-[8px]"
                        width="8"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon
                            points="0, 0 15, 15 15, 0"
                            fill="currentColor"
                            fillOpacity="0.8"
                        />
                    </svg>
                    <div
                        className={`absolute -mt-3 -ml-2 text-sm text-white font-bold px-2 rounded-t rounded-br ${props.flair.className}`}
                    >
                        {props.flair.label}
                    </div>
                </>
            )}
            {props.children}
        </div>
    );
};

interface DashboardTabsProps {
    tabs: {
        name: string;
        child: ReactNode;
        classname?: string;
    }[];
}

export const DashboardTabs = (props: DashboardTabsProps) => (
    <Tab.Group>
        <Tab.List className="flex gap-1 text-sm mt-4">
            {props.tabs.map((t) => (
                <Tab
                    className={`
                        h-10 px-4 capitalize rounded-t border border-ae-gray-250 -mb-[1px]
                        ui-selected:text-ae-blue-500 ui-selected:border-b-white ui-selected:bg-white
                        ${t.classname}
                    `}
                    key={t.name}
                >
                    {t.name}
                </Tab>
            ))}
        </Tab.List>
        <Tab.Panels>
            {props.tabs.map((t) => (
                <Tab.Panel
                    key={t.name}
                    className="rounded bg-white border border-gray-300"
                >
                    {t.child}
                </Tab.Panel>
            ))}
        </Tab.Panels>
    </Tab.Group>
);
