import axios from "axios";
import {
    Configuration,
    AccountsApi as _AccountsApi,
    AdminApi as _AdminApi,
    AuthApi as _AuthApi,
    DatauploadApi as _DatauploadApi,
    DatadownloadsApi as _DatadownloadsApi,
    EmissionRecordsApi as _EmissionRecordsApi,
    InfrastructureApi as _InfrastructureApi,
    MapApi as _MapApi,
    EmissioneventsApi as _EmissioneventsApi,
    RootcausesApi as _RootcausesApi,
    DataProvidersApi as _DataProvidersApi,
    DataImportApi as _DataImportApi,
    NotificationsApi as _NotificationsApi,
    DataPointsApi as _DataPointsApi,
    GeodataApi as _GeodataApi,
} from "./generated";
import { BACKEND_URL } from "../constants/globals";

const getApiConfig = (token?: string) => {
    const config = {
        basePath: BACKEND_URL,
        apiKey: undefined,
        // Using middleware to intercept 401 responses and redirect to login page.
        middleware: [
            {
                post: async (context) => {
                    if (
                        context.response.status === 401 &&
                        !window.location.pathname.includes("/login")
                    ) {
                        const pathWithQueryAndHash =
                            window.location.pathname +
                            window.location.search +
                            window.location.hash;
                        // Redirect to the login page with the 'next' parameter set to the current URL
                        window.location.href = `/login?next=${encodeURIComponent(
                            pathWithQueryAndHash,
                        )}`;
                    }
                },
            },
        ],
    };
    if (token) {
        config.apiKey = `Token ${token}`;
    }

    return new Configuration(config);
};

export const AccountsApi = (token: string) => {
    return new _AccountsApi(getApiConfig(token));
};

export const AuthApi = (token?: string) => {
    return new _AuthApi(getApiConfig(token));
};

export const DatauploadApi = (token: string) => {
    return new _DatauploadApi(getApiConfig(token));
};

export const DatadownloadsApi = (token: string) => {
    return new _DatadownloadsApi(getApiConfig(token));
};

export const EmissionRecordsApi = (token: string) => {
    return new _EmissionRecordsApi(getApiConfig(token));
};

export const InfrastructureApi = (token: string) => {
    return new _InfrastructureApi(getApiConfig(token));
};

export const MapApi = (token: string) => {
    return new _MapApi(getApiConfig(token));
};

export const EmissionEventsApi = (token: string) => {
    return new _EmissioneventsApi(getApiConfig(token));
};

export const RootCausesApi = (token: string) => {
    return new _RootcausesApi(getApiConfig(token));
};

export const DataProvidersApi = (token: string) => {
    return new _DataProvidersApi(getApiConfig(token));
};

export const DataImportApi = (token: string) => {
    return new _DataImportApi(getApiConfig(token));
};

export const NotificationsApi = (token: string) => {
    return new _NotificationsApi(getApiConfig(token));
};

export const DataPointsApi = (token: string) => {
    return new _DataPointsApi(getApiConfig(token));
};

export const AdminApi = (token: string) => {
    return new _AdminApi(getApiConfig(token));
};

export const GeodataApi = (token: string) => {
    return new _GeodataApi(getApiConfig(token));
};
