/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminAuthenticationResponse,
  LoginViewRequest,
  LoginViewResponse,
  PasswordReset,
  PasswordResetRequest,
  SSOEnabledResponse,
  SSORequiredCheckRequest,
  SSORequiredCheckResponse,
  SSORetrieveTokenResponse,
  SecondFactorViewRequest,
  SecondFactorViewResponse,
} from '../models/index';
import {
    AdminAuthenticationResponseFromJSON,
    AdminAuthenticationResponseToJSON,
    LoginViewRequestFromJSON,
    LoginViewRequestToJSON,
    LoginViewResponseFromJSON,
    LoginViewResponseToJSON,
    PasswordResetFromJSON,
    PasswordResetToJSON,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    SSOEnabledResponseFromJSON,
    SSOEnabledResponseToJSON,
    SSORequiredCheckRequestFromJSON,
    SSORequiredCheckRequestToJSON,
    SSORequiredCheckResponseFromJSON,
    SSORequiredCheckResponseToJSON,
    SSORetrieveTokenResponseFromJSON,
    SSORetrieveTokenResponseToJSON,
    SecondFactorViewRequestFromJSON,
    SecondFactorViewRequestToJSON,
    SecondFactorViewResponseFromJSON,
    SecondFactorViewResponseToJSON,
} from '../models/index';

export interface Auth2faCreateRequest {
    secondFactorViewRequest: SecondFactorViewRequest;
}

export interface AuthLoginCreateRequest {
    loginViewRequest: LoginViewRequest;
}

export interface AuthPasswordResetCreateRequest {
    passwordResetRequest?: PasswordResetRequest;
}

export interface AuthPasswordResetUpdateRequest {
    passwordResetRequest?: PasswordResetRequest;
}

export interface AuthSsoRequiredCreateRequest {
    sSORequiredCheckRequest: SSORequiredCheckRequest;
}

export interface AuthSsoRetrieveTokenRetrieveRequest {
    code: string;
    provider: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Check if token is valid and returns frontend API key.
     */
    async auth2faCreateRaw(requestParameters: Auth2faCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecondFactorViewResponse>> {
        if (requestParameters['secondFactorViewRequest'] == null) {
            throw new runtime.RequiredError(
                'secondFactorViewRequest',
                'Required parameter "secondFactorViewRequest" was null or undefined when calling auth2faCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/2fa`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecondFactorViewRequestToJSON(requestParameters['secondFactorViewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecondFactorViewResponseFromJSON(jsonValue));
    }

    /**
     * Check if token is valid and returns frontend API key.
     */
    async auth2faCreate(requestParameters: Auth2faCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecondFactorViewResponse> {
        const response = await this.auth2faCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates session authentication from token authentication.  Check if user is authenticated and has staff/admin permissions.
     */
    async authAdminAuthCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminAuthenticationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/auth/admin-auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminAuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Creates session authentication from token authentication.  Check if user is authenticated and has staff/admin permissions.
     */
    async authAdminAuthCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminAuthenticationResponse> {
        const response = await this.authAdminAuthCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Deletes session key.
     */
    async authAdminAuthDestroyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/auth/admin-auth`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes session key.
     */
    async authAdminAuthDestroy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authAdminAuthDestroyRaw(initOverrides);
    }

    /**
     * Check if user is authenticated and has staff/admin permissions.  Return a session key if user is authenticated.
     */
    async authAdminAuthRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminAuthenticationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/auth/admin-auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminAuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Check if user is authenticated and has staff/admin permissions.  Return a session key if user is authenticated.
     */
    async authAdminAuthRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminAuthenticationResponse> {
        const response = await this.authAdminAuthRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if user and email are correct and creates a token for 2nd step authentication.
     */
    async authLoginCreateRaw(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginViewResponse>> {
        if (requestParameters['loginViewRequest'] == null) {
            throw new runtime.RequiredError(
                'loginViewRequest',
                'Required parameter "loginViewRequest" was null or undefined when calling authLoginCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginViewRequestToJSON(requestParameters['loginViewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginViewResponseFromJSON(jsonValue));
    }

    /**
     * Check if user and email are correct and creates a token for 2nd step authentication.
     */
    async authLoginCreate(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginViewResponse> {
        const response = await this.authLoginCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Custom logout view with extra headers to clear cache.
     */
    async authLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/auth/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Custom logout view with extra headers to clear cache.
     */
    async authLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLogoutCreateRaw(initOverrides);
    }

    /**
     * Class with fixed serializer
     */
    async authLogoutallCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/auth/logoutall/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Class with fixed serializer
     */
    async authLogoutallCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLogoutallCreateRaw(initOverrides);
    }

    /**
     * Endpoint to create a password reset request and trigger email to user.
     */
    async authPasswordResetCreateRaw(requestParameters: AuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordReset>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/password-reset/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters['passwordResetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetFromJSON(jsonValue));
    }

    /**
     * Endpoint to create a password reset request and trigger email to user.
     */
    async authPasswordResetCreate(requestParameters: AuthPasswordResetCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordReset> {
        const response = await this.authPasswordResetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint to change user password after password reset.
     */
    async authPasswordResetUpdateRaw(requestParameters: AuthPasswordResetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordReset>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/password-reset/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters['passwordResetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetFromJSON(jsonValue));
    }

    /**
     * Endpoint to change user password after password reset.
     */
    async authPasswordResetUpdate(requestParameters: AuthPasswordResetUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordReset> {
        const response = await this.authPasswordResetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if user email requires SSO check.
     */
    async authSsoRequiredCreateRaw(requestParameters: AuthSsoRequiredCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SSORequiredCheckResponse>> {
        if (requestParameters['sSORequiredCheckRequest'] == null) {
            throw new runtime.RequiredError(
                'sSORequiredCheckRequest',
                'Required parameter "sSORequiredCheckRequest" was null or undefined when calling authSsoRequiredCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/sso-required`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SSORequiredCheckRequestToJSON(requestParameters['sSORequiredCheckRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSORequiredCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check if user email requires SSO check.
     */
    async authSsoRequiredCreate(requestParameters: AuthSsoRequiredCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SSORequiredCheckResponse> {
        const response = await this.authSsoRequiredCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if SSO is enabled platform-wide.
     */
    async authSsoRequiredRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SSOEnabledResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/sso-required`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSOEnabledResponseFromJSON(jsonValue));
    }

    /**
     * Check if SSO is enabled platform-wide.
     */
    async authSsoRequiredRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SSOEnabledResponse> {
        const response = await this.authSsoRequiredRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve SSO token from code.
     */
    async authSsoRetrieveTokenRetrieveRaw(requestParameters: AuthSsoRetrieveTokenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SSORetrieveTokenResponse>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling authSsoRetrieveTokenRetrieve().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling authSsoRetrieveTokenRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/sso-retrieve-token/{provider}/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSORetrieveTokenResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve SSO token from code.
     */
    async authSsoRetrieveTokenRetrieve(requestParameters: AuthSsoRetrieveTokenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SSORetrieveTokenResponse> {
        const response = await this.authSsoRetrieveTokenRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
