import { Navigate, Outlet, useOutlet } from "react-router";
import { ROUTES } from "..";
import { NavLink } from "react-router-dom";

const NavbarLink = (props: {
    label: string;
    route: string;
    classname?: string;
    disabled?: boolean;
}) => (
    <NavLink
        to={!props.disabled && props.route}
        onClick={(e) => {
            if (props.disabled) {
                e.preventDefault();
            }
        }}
        className={({ isActive }) => `
            h-10 px-3 w-fit text-sm
            flex items-center justify-center
            border border-ae-gray-250 rounded-t -mb-[1px]
            ${props.disabled && "text-gray-400 cursor-default"}
            ${
                !props.disabled && isActive
                    ? "border-b-ae-gray-100 font-bold text-ae-blue-600"
                    : "bg-neutral-50"
            }
            ${props.classname}
        `}
    >
        {props.label}
    </NavLink>
);

const Navbar = () => (
    <nav className="w-full inline-flex gap-2 px-4 border-b border-gray-300">
        <NavbarLink
            label="3rd Party Detections"
            route={ROUTES.DASHBOARD_THIRD_PARTY}
        />
        <NavbarLink
            label="My Company Monitoring"
            route={ROUTES.DASHBOARD_SELF_REPORTED}
        />
        <NavbarLink
            label="EPA Super Emitter Program"
            route={ROUTES.DASHBOARD_EPA_SEP}
            disabled
        />
    </nav>
);

export const MainDashboardContainer = () => {
    const outlet = useOutlet();

    if (outlet === null) {
        return <Navigate to={ROUTES.DASHBOARD_THIRD_PARTY} />;
    }

    return (
        <div className="h-screen w-full px-5 py-10 overflow-auto">
            {/* Top bar & navigation */}
            <h1 className="ml-7 text-xl font-semibold mb-3">Emissions</h1>
            <Navbar />

            {/* Page content */}
            <div className="w-full h-full">
                <Outlet />
            </div>
        </div>
    );
};
