import { useEffect, useMemo, useRef, useState } from "react";
import { MapBase } from "../../MapV2/Map";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useMapApiClient } from "../../../hooks";
import {
    EmissionImagesLayer,
    EmissionRecordsLayer,
    PlumeOutlinesLayer,
} from "../../MapV2/layers/emissions";
import {
    CROSSHAIR_ICONS,
    EMISSION_COLORS,
    MAP_ZOOM_SHOW_DETAILS,
} from "../../MapV2/constants";
import { useMap } from "../../MapV2/hooks/mapState";
import * as turf from "@turf/turf";
import { EmissionHover } from "./Emissions";
import { InfrastructureLayer } from "../../MapV2/layers/infrastructure_legacy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { CustomSwitch } from "../../../ui/CustomSwitch";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    InfrastructureMapList,
} from "../../../apiClient/generated";

const useInfrastructurePlumeQueries = (
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    },
    prefix: string = "",
    infrastructureId?: string,
    tentativeInfrastructureId?: string,
) => {
    const apiClient = useMapApiClient();

    const queryParams = useMemo(
        () => ({
            providerWithSource: filters.providerWithSource?.length
                ? (JSON.stringify(filters.providerWithSource) as any)
                : undefined,
            detectionDateRangeAfter: filters.detectionDateRangeAfter
                ? new Date(filters.detectionDateRangeAfter)
                : undefined,
            detectionDateRangeBefore: filters.detectionDateRangeBefore
                ? new Date(filters.detectionDateRangeBefore)
                : undefined,
        }),
        [filters],
    );

    return useQueries({
        queries: [
            {
                queryKey: [
                    `${prefix}PlumeImagesMiniMap`,
                    infrastructureId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapPlumeImagesList({
                        ...queryParams,
                        infrastructure: infrastructureId
                            ? [infrastructureId]
                            : undefined,
                        tentativeInfrastructure: tentativeInfrastructureId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [
                    `${prefix}PlumeOutlinesMiniMap`,
                    infrastructureId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapPlumeOutlinesList({
                        ...queryParams,
                        infrastructure: infrastructureId
                            ? [infrastructureId]
                            : undefined,
                        tentativeInfrastructure: tentativeInfrastructureId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
        ],
    });
};

const useInfrastructureEmissionQueries = (
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    },
    prefix: string = "",
    infrastructureId?: string,
    tentativeInfrastructureId?: string,
) => {
    const apiClient = useMapApiClient();

    const queryParams = useMemo(
        () => ({
            providerWithSource: filters.providerWithSource?.length
                ? (JSON.stringify(filters.providerWithSource) as any)
                : undefined,
            detectionDateRangeAfter: filters.detectionDateRangeAfter
                ? new Date(filters.detectionDateRangeAfter)
                : undefined,
            detectionDateRangeBefore: filters.detectionDateRangeBefore
                ? new Date(filters.detectionDateRangeBefore)
                : undefined,
        }),
        [filters],
    );

    return useQueries({
        queries: [
            {
                queryKey: [
                    `${prefix}EmissionRecordsEPAMiniMap`,
                    infrastructureId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["EPA"],
                        infrastructure: infrastructureId,
                        tentativeInfrastructure: tentativeInfrastructureId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [
                    `${prefix}EmissionRecordsSelfReportedMiniMap`,
                    infrastructureId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["SELF_REPORTED"],
                        infrastructure: infrastructureId,
                        tentativeInfrastructure: tentativeInfrastructureId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [
                    `${prefix}EmissionRecordsThirdPartyMiniMap`,
                    infrastructureId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["THIRD_PARTY"],
                        infrastructure: infrastructureId,
                        tentativeInfrastructure: tentativeInfrastructureId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
        ],
    });
};

const useEmissionLayers = (
    infrastructure: InfrastructureMapList[],
    emissionQueries: any[],
    plumeQueries: any[],
    tentativeEmissionQueries: any[],
    tentativePlumeQueries: any[],
    options: {
        showPlumes: boolean;
        plumeOpacity: number;
        selectedContext?: string;
        hoverContext?: string;
        cursorPosition?: { x: number; y: number } | null;
        enabledData: string[];
    },
) => {
    const {
        showPlumes,
        plumeOpacity,
        selectedContext,
        hoverContext,
        cursorPosition,
        enabledData,
    } = options;

    return useMemo(() => {
        const layers = [];

        // Look for related plume ID from data
        const contextId = selectedContext || hoverContext;
        let relatedPlume;
        [
            tentativeEmissionQueries[0],
            tentativeEmissionQueries[1],
            tentativeEmissionQueries[2],
            emissionQueries[0],
            emissionQueries[1],
            emissionQueries[2],
        ].forEach((query) => {
            if (!relatedPlume && contextId && query?.data) {
                const found = query.data.find((i) => i.id === contextId);
                if (found?.plumeImage) {
                    relatedPlume = found.plumeImage;
                }
            }
        });

        // Helper for emission layers
        const createEmissionLayer = (
            data: any[],
            color: any,
            icon: any,
            layerPrefix: string,
            layerSuffix: string,
        ) => {
            if (!data) return;
            layers.push(
                EmissionRecordsLayer(
                    data.filter((i) => {
                        if (selectedContext) return i.id === selectedContext;
                        return hoverContext && !cursorPosition
                            ? i.id === hoverContext
                            : true;
                    }),
                    true,
                    MAP_ZOOM_SHOW_DETAILS + 1,
                    color,
                    icon,
                    `${layerPrefix}${layerSuffix}`,
                ),
            );
        };

        // First image layers
        if (enabledData.includes("TENTATIVE_EMISSIONS")) {
            if (tentativePlumeQueries[1].data) {
                layers.push(
                    PlumeOutlinesLayer(
                        tentativePlumeQueries[1].data.filter((i) => {
                            if (i.hasPlume && showPlumes) return false;
                            if (selectedContext)
                                return i.emissionRecordIds.includes(
                                    selectedContext,
                                );
                            if (hoverContext)
                                return i.emissionRecordIds.includes(
                                    hoverContext,
                                );
                            return true;
                        }),
                        "tentative_plume_outlines",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
            if (tentativePlumeQueries[0].data && showPlumes) {
                layers.push(
                    EmissionImagesLayer(
                        tentativePlumeQueries[0].data.filter(
                            (i) =>
                                !contextId ||
                                (relatedPlume && relatedPlume === i.id),
                        ),
                        "tentative_plume_images",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
        }

        if (enabledData.includes("EMISSIONS")) {
            if (plumeQueries[1].data) {
                layers.push(
                    PlumeOutlinesLayer(
                        plumeQueries[1].data.filter((i) => {
                            if (i.hasPlume && showPlumes) return false;
                            if (selectedContext)
                                return i.emissionRecordIds.includes(
                                    selectedContext,
                                );
                            if (hoverContext)
                                return i.emissionRecordIds.includes(
                                    hoverContext,
                                );
                            return true;
                        }),
                        "emissions_plume_outlines",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }

            if (plumeQueries[0].data && showPlumes) {
                layers.push(
                    EmissionImagesLayer(
                        plumeQueries[0].data.filter(
                            (i) =>
                                !contextId ||
                                (relatedPlume && relatedPlume === i.id),
                        ),
                        "emissions_plume_images",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
        }

        // Then infrastructure
        if (infrastructure) {
            layers.push(
                InfrastructureLayer({
                    data: infrastructure,
                    enabledLayers: ["infrastructure"],
                    disableHighlight: true,
                }),
            );
        }

        // Then emission layers
        if (enabledData.includes("TENTATIVE_EMISSIONS")) {
            createEmissionLayer(
                tentativeEmissionQueries[0].data,
                EMISSION_COLORS.epa,
                CROSSHAIR_ICONS.epa,
                "tentative_",
                "emissions_epa",
            );
            createEmissionLayer(
                tentativeEmissionQueries[1].data,
                EMISSION_COLORS.operatorProvided,
                CROSSHAIR_ICONS.operatorProvided,
                "tentative_",
                "emissions_operatorProvided",
            );
            createEmissionLayer(
                tentativeEmissionQueries[2].data,
                EMISSION_COLORS.thirdParty,
                CROSSHAIR_ICONS.thirdParty,
                "tentative_",
                "emissions_thirdParty",
            );
        }
        if (enabledData.includes("EMISSIONS")) {
            createEmissionLayer(
                emissionQueries[0].data,
                EMISSION_COLORS.epa,
                CROSSHAIR_ICONS.epa,
                "emissions_",
                "emissions_epa",
            );
            createEmissionLayer(
                emissionQueries[1].data,
                EMISSION_COLORS.operatorProvided,
                CROSSHAIR_ICONS.operatorProvided,
                "emissions_",
                "emissions_operatorProvided",
            );
            createEmissionLayer(
                emissionQueries[2].data,
                EMISSION_COLORS.thirdParty,
                CROSSHAIR_ICONS.thirdParty,
                "emissions_",
                "emissions_thirdParty",
            );
        }

        return layers;
    }, [
        emissionQueries,
        plumeQueries,
        tentativeEmissionQueries,
        tentativePlumeQueries,
        showPlumes,
        plumeOpacity,
        selectedContext,
        hoverContext,
        cursorPosition,
        enabledData,
    ]);
};

interface InfrastructureDetailMapProps {
    infrastructureId: string;
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    };
    enabledData: string[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
}

export const InfrastructureDetailMap = (
    props: InfrastructureDetailMapProps,
) => {
    const containerRef = useRef<HTMLDivElement>();
    const [mapHeight, setMapHeight] = useState(350);
    const apiClient = useMapApiClient();
    const [cursorPosition, setCursorPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);

    const [showPlumes, setShowPlumes] = useState(true);
    const [plumeOpacity, setPlumeOpacity] = useState(0.2);
    const [oldPlumeOpacity, setOldPlumeOpacity] = useState(0);

    // Map setup
    const { flyTo } = useMap("infrastructureDetailMap");

    // Retrieve infrastructure tree
    const infrastructureQuery = useQuery({
        queryKey: ["infrastructureTreeMiniMap", props.infrastructureId],
        queryFn: async () => {
            const response = await apiClient.mapInfrastructureList({
                parent: [props.infrastructureId],
            });
            return response.results;
        },
    });

    useEffect(() => {
        if (infrastructureQuery.data) {
            const points = turf.points(
                infrastructureQuery.data
                    .filter((i) => i.location)
                    .map((i) => i.location.coordinates),
            );
            const center = turf.center(points);
            flyTo(
                center.geometry.coordinates[1],
                center.geometry.coordinates[0],
                14,
                true,
            );
        }
    }, [infrastructureQuery.data]);

    const emissionQueries = useInfrastructureEmissionQueries(
        props.enabledData.includes("EMISSIONS"),
        props.filters,
        "emissions_",
        props.infrastructureId,
    );
    const plumeQueries = useInfrastructurePlumeQueries(
        props.enabledData.includes("EMISSIONS"),
        props.filters,
        "emissions_",
        props.infrastructureId,
    );
    const tentativeEmissionQueries = useInfrastructureEmissionQueries(
        props.enabledData.includes("TENTATIVE_EMISSIONS"),
        props.filters,
        "tentative_",
        undefined,
        props.infrastructureId,
    );
    const tentativePlumeQueries = useInfrastructurePlumeQueries(
        props.enabledData.includes("TENTATIVE_EMISSIONS"),
        props.filters,
        "tentative_",
        undefined,
        props.infrastructureId,
    );

    const layers = useEmissionLayers(
        infrastructureQuery.data,
        emissionQueries,
        plumeQueries,
        tentativeEmissionQueries,
        tentativePlumeQueries,
        {
            showPlumes: showPlumes,
            plumeOpacity: plumeOpacity,
            selectedContext: props.selectedContext,
            hoverContext: props.hoverContext,
            cursorPosition: cursorPosition,
            enabledData: props.enabledData,
        },
    );

    useEffect(() => {
        if (containerRef.current) {
            setMapHeight(containerRef.current.clientHeight);
        }
    }, [containerRef]);

    return (
        <div
            ref={containerRef}
            onMouseLeave={() => setCursorPosition(undefined)}
            className={`relative w-full rounded overflow-hidden h-[${mapHeight}px]`}
        >
            {props.hoverContext && cursorPosition && (
                <EmissionHover
                    emissionId={props.hoverContext}
                    style={{
                        position: "absolute",
                        left: cursorPosition.x + 10,
                        top: cursorPosition.y + 15,
                        pointerEvents: "none",
                        transform: "translate(-50%, 0)",
                        zIndex: 100,
                    }}
                />
            )}
            <MapBase
                mapId="infrastructureDetailMap"
                layers={layers}
                showScaleControl
                showZoomControl
                onHover={(info) => {
                    if (info.object && info.layer.id.includes("emissions")) {
                        setCursorPosition({ x: info.x, y: info.y });
                        props.setHoverContext(info.object.properties.id);
                    } else {
                        setCursorPosition(null);
                        props.setHoverContext();
                    }
                }}
                onLeftClick={({ info }) => {
                    if (info.length === 0) {
                        props.setSelectedContext();
                    } else if (info[0].layer.id.includes("emissions")) {
                        props.setSelectedContext(
                            info[0].object.properties.id ==
                                props.selectedContext
                                ? undefined
                                : info[0].object.properties.id,
                        );
                    }
                }}
            />
            <div className="absolute top-4 w-full flex justify-center">
                <div className="w-3/4 flex gap-3 bg-white rounded px-4 py-3 text-sm whitespace-nowrap items-center">
                    Plume opacity
                    <button
                        className="px-1 rounded hover:bg-gray-200"
                        onClick={() => {
                            setPlumeOpacity(oldPlumeOpacity);
                            setOldPlumeOpacity(plumeOpacity);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={plumeOpacity ? faEye : faEyeSlash}
                            className="w-4"
                        />
                    </button>
                    <input
                        type="range"
                        className="w-full"
                        value={plumeOpacity}
                        min={0}
                        step={0.01}
                        max={1}
                        onChange={(e) => {
                            setPlumeOpacity(e.target.valueAsNumber);
                        }}
                    />
                    {(plumeOpacity * 100).toFixed(0)} %
                    <div className="flex w-content items-center gap-3 ml-4">
                        <CustomSwitch
                            checked={showPlumes}
                            onChange={() => {
                                setShowPlumes(!showPlumes);
                            }}
                            size="xs"
                        />
                        <div className="w-24">
                            {!showPlumes ? "Outlines only" : "Outlines/plumes"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
