import { useState, useEffect, useMemo } from "react";
import {
    MapPlumeImagesListDataSourceEnum,
    PlumeImage,
    EmissionRecordMap,
    MapEmissionRecordsListRequest,
    PlumeOulines,
    DataPointMap,
} from "../../../apiClient/generated";
import { useMapApiClient } from "../../../hooks";
import {
    getBinForRateOrConcentration,
    MAP_ZOOM_SHOW_DETAILS,
} from "../constants";
import { DataPointMapWithBin, EmissionRecordMapWithBin } from "../CustomTypes";
import { useMapDataLoader } from "./dataLoader";
import Supercluster from "supercluster";
import {
    EmissionImagesLayer,
    EmissionRecordsLayers,
    PlumeOutlinesLayer,
} from "../layers/emissions";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import { generateFetchAllPagesFn } from "./common";
import { useMap } from "./mapState";
import { useMapData } from "./mapDataAndFilters";

/**
 * Emission records: custom hook that loads emissions-related
 * data (points and plume images) based on filter values and
 * returns map layers for display.
 */
export const useEmissionRecordsOnMap = (
    enabled: boolean,
    dataName: string,
    enableOverviews: boolean,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        provider?: string[] | "all";
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    color: [number, number, number],
    iconAtlas: string,
    // FIXME: Type this properly
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const {
        selectedContext,
        mapSettings: { plumeOpacity, showPlumes },
    } = useMapData("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);
    const [emissions, setEmissions] = useState<EmissionRecordMapWithBin[]>([]);
    const [plumeImages, setPlumeImages] = useState<PlumeImage[]>([]);
    const [plumeOutlines, setPlumeOutlines] = useState<PlumeOulines[]>([]);

    // Emission data
    const emissionsFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            EmissionRecordMap,
            MapEmissionRecordsListRequest
        >(
            (props) => apiClient.mapEmissionRecordsList(props),
            (newData) => {
                setEmissions((data) =>
                    data.concat(
                        newData.map((i) => {
                            return {
                                ...i,
                                mapDotSize: getBinForRateOrConcentration(
                                    i.detectedRate / 1000,
                                    i.concentration,
                                ).size,
                            };
                        }),
                    ),
                );
            },
            filters,
        );
    }, [apiClient, filters]);

    const emissionsDataLoader = useMapDataLoader({
        loadDataCallback: emissionsFetchFn,
        zoomToStartFetching: 1,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Plume images
    const plumesFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            PlumeImage,
            MapEmissionRecordsListRequest
        >(
            (props) =>
                apiClient.mapPlumeImagesList({
                    ...props,
                    infrastructure: props.infrastructure
                        ? [props.infrastructure]
                        : undefined,
                }),
            (newData) => setPlumeImages((data) => data.concat(newData)),
            filters,
        );
    }, [apiClient, filters]);

    const plumeImagesDataLoader = useMapDataLoader({
        loadDataCallback: plumesFetchFn,
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all") &&
            showPlumes,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Plume images
    const outlinesFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            PlumeOulines,
            MapEmissionRecordsListRequest
        >(
            (props) =>
                apiClient.mapPlumeOutlinesList({
                    ...props,
                    infrastructure: props.infrastructure
                        ? [props.infrastructure]
                        : undefined,
                }),
            (newData) => setPlumeOutlines((data) => data.concat(newData)),
            filters,
        );
    }, [apiClient, filters]);

    const plumeOutlinesDataLoader = useMapDataLoader({
        loadDataCallback: outlinesFetchFn,
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setEmissions([]);
        setPlumeImages([]);
        setPlumeOutlines([]);
        emissionsDataLoader.resetState();
        plumeImagesDataLoader.resetState();
        plumeOutlinesDataLoader.resetState();
    }, [filters, filterByArea]);

    // Reset plume data when zooming out
    // To avoid rendering an excessive number of plume
    // layers outside of the screen boundaries.
    useEffect(() => {
        if (currentZoom < MAP_ZOOM_SHOW_DETAILS - 1) {
            if (plumeImages.length > 0) {
                setPlumeImages([]);
                plumeImagesDataLoader.resetState();
            }
            if (plumeOutlines.length > 0) {
                setPlumeOutlines([]);
                plumeOutlinesDataLoader.resetState();
            }
        }
    }, [currentZoom]);

    // Set up emission clustering
    const clusterData = useMemo(() => {
        const index = new Supercluster({
            radius: 50,
            maxZoom: 9,
            map: (props) => ({
                highestRate: props.detectedRate,
                highestConcentration: props.concentration,
            }),
            reduce: (accumulated, props) => {
                accumulated.highestRate = Math.max(
                    accumulated.highestRate,
                    props.highestRate,
                );
                accumulated.highestConcentration = Math.max(
                    accumulated.highestConcentration,
                    props.highestConcentration,
                );
            },
        });
        index.load(
            emissions.map(
                (i) =>
                    ({
                        type: "Feature",
                        geometry: i.location,
                        properties: i,
                    }) as any,
            ),
        );
        return index;
    }, [emissions]);

    const clusteredPoints = useMemo(() => {
        if (!areaOnScreen) {
            return [];
        }

        if (currentZoom >= 9) {
            return [];
        }

        // Annotate zoom level in which cluster is broken down.
        return clusterData
            .getClusters([-180, -90, 180, 90], currentZoom)
            .map((i: any) => {
                if (i.properties.cluster) {
                    i.properties.zoom = clusterData.getClusterExpansionZoom(
                        i.properties.cluster_id,
                    );
                    i.properties.tooltip = "Click to zoom in";
                    i.properties.mapDotSize = getBinForRateOrConcentration(
                        i.properties.highestRate / 1000,
                        i.properties.highestConcentration,
                    ).size;
                }
                return i;
            });
    }, [clusterData, currentZoom, areaOnScreen]);

    // Layers
    const emissionLayers = useMemo(() => {
        return [
            EmissionRecordsLayers(
                createFeatureCollection(emissions).features,
                enabled,
                currentZoom,
                color,
                iconAtlas,
                dataName,
                // Only send clustered data if overviews are enabled.
                enableOverviews ? clusteredPoints : undefined,
            ),
        ];
    }, [
        emissions,
        enabled,
        currentZoom,
        color,
        iconAtlas,
        dataName,
        enableOverviews,
        clusteredPoints,
    ]);

    const plumeLayers = useMemo(() => {
        const layers = [];
        const visiblePlumeOutlines = plumeOutlines.filter((outline) => {
            if (showPlumes && outline.hasPlume) {
                return false;
            }
            if (selectedContext.dataPointId) {
                return selectedContext.dataPointId === outline.id;
            }
            if (selectedContext.emissionRecordId) {
                return outline.emissionRecordIds.some(
                    (i) => i === selectedContext.emissionRecordId,
                );
            }
            return true;
        });

        // Plume outlines first, so they render at the bottom
        if (visiblePlumeOutlines.length > 0) {
            layers.push(
                PlumeOutlinesLayer(
                    visiblePlumeOutlines,
                    `plume_outlines_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }

        // Then plume images, only if they are enabled
        if (selectedContext.relatedPlume !== null && showPlumes) {
            layers.push(
                EmissionImagesLayer(
                    selectedContext.relatedPlume
                        ? plumeImages.filter(
                              (i) => i.id === selectedContext.relatedPlume,
                          )
                        : plumeImages,
                    `plumes_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }
        return layers;
    }, [
        enabled,
        plumeImages,
        currentZoom,
        selectedContext,
        plumeOpacity,
        emissions,
        showPlumes,
    ]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissionLayers,
        plumeLayers,
    };
};

/**
 * Public data points.
 *
 * At this point this is just a copy and paste of the method above
 * minus the clustering code and with changes to accomodate
 * the DataPoint model.
 *
 * // FIXME: Build a re-usable thing and remove duplication.
 */
export const usePublicDataPointsOnMap = (
    enabled: boolean,
    dataName: string,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        provider?: string[] | "all";
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    color: [number, number, number],
    iconAtlas: string,
    // FIXME: Type this properly
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const {
        selectedContext,
        mapSettings: { plumeOpacity, showPlumes },
    } = useMapData("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);
    const [emissions, setEmissions] = useState<DataPointMapWithBin[]>([]);
    const [plumeImages, setPlumeImages] = useState<PlumeImage[]>([]);
    const [plumeOutlines, setPlumeOutlines] = useState<PlumeOulines[]>([]);

    // Emission data
    const emissionsFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            DataPointMap,
            MapEmissionRecordsListRequest
        >(
            (props) =>
                apiClient.mapPublicEmissionsList({
                    ...props,
                    infrastructure: props.infrastructure
                        ? [props.infrastructure]
                        : undefined,
                }),
            (newData) => {
                setEmissions((data) =>
                    data.concat(
                        newData.map((i) => {
                            return {
                                ...i,
                                mapDotSize: getBinForRateOrConcentration(
                                    i.detectedRate / 1000,
                                    i.concentration,
                                ).size,
                            };
                        }),
                    ),
                );
            },
            filters,
        );
    }, [apiClient, filters]);

    const emissionsDataLoader = useMapDataLoader({
        loadDataCallback: emissionsFetchFn,
        zoomToStartFetching: 1,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Plume images
    const plumesFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            PlumeImage,
            MapEmissionRecordsListRequest
        >(
            (props) =>
                apiClient.mapPublicPlumeImagesList({
                    ...props,
                    infrastructure: props.infrastructure
                        ? [props.infrastructure]
                        : undefined,
                }),
            (newData) => setPlumeImages((data) => data.concat(newData)),
            filters,
        );
    }, [apiClient, filters]);

    const plumeImagesDataLoader = useMapDataLoader({
        loadDataCallback: plumesFetchFn,
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all") &&
            showPlumes,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Plume images
    const outlinesFetchFn = useMemo(() => {
        return generateFetchAllPagesFn<
            PlumeOulines,
            MapEmissionRecordsListRequest
        >(
            (props) =>
                apiClient.mapPublicPlumeOutlinesList({
                    ...props,
                    infrastructure: props.infrastructure
                        ? [props.infrastructure]
                        : undefined,
                }),
            (newData) => setPlumeOutlines((data) => data.concat(newData)),
            filters,
        );
    }, [apiClient, filters]);

    const plumeOutlinesDataLoader = useMapDataLoader({
        loadDataCallback: outlinesFetchFn,
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.provider.length > 0 || filters.provider === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setEmissions([]);
        setPlumeImages([]);
        setPlumeOutlines([]);
        emissionsDataLoader.resetState();
        plumeImagesDataLoader.resetState();
        plumeOutlinesDataLoader.resetState();
    }, [filters, filterByArea]);

    // Reset plume data when zooming out
    // To avoid rendering an excessive number of plume
    // layers outside of the screen boundaries.
    useEffect(() => {
        if (currentZoom < MAP_ZOOM_SHOW_DETAILS - 1) {
            if (plumeImages.length > 0) {
                setPlumeImages([]);
                plumeImagesDataLoader.resetState();
            }
            if (plumeOutlines.length > 0) {
                setPlumeOutlines([]);
                plumeOutlinesDataLoader.resetState();
            }
        }
    }, [currentZoom]);

    // Layers
    const emissionLayers = useMemo(() => {
        return [
            EmissionRecordsLayers(
                createFeatureCollection(emissions).features,
                enabled,
                currentZoom,
                color,
                iconAtlas,
                dataName,
                undefined,
                undefined,
                true,
            ),
        ];
    }, [emissions, enabled, currentZoom, color, iconAtlas, dataName]);

    const plumeLayers = useMemo(() => {
        const layers = [];
        const visiblePlumeOutlines = plumeOutlines.filter((outline) => {
            if (showPlumes && outline.hasPlume) {
                return false;
            }
            if (selectedContext.dataPointId) {
                return selectedContext.dataPointId === outline.id;
            }
            if (selectedContext.emissionRecordId) {
                return outline.emissionRecordIds.some(
                    (i) => i === selectedContext.emissionRecordId,
                );
            }
            return true;
        });

        // Plume outlines first, so they render at the bottom
        if (visiblePlumeOutlines.length > 0) {
            layers.push(
                PlumeOutlinesLayer(
                    visiblePlumeOutlines,
                    `plume_outlines_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }

        // Then plume images, only if they are enabled
        if (selectedContext.relatedPlume !== null && showPlumes) {
            layers.push(
                EmissionImagesLayer(
                    selectedContext.relatedPlume
                        ? plumeImages.filter(
                              (i) => i.id === selectedContext.relatedPlume,
                          )
                        : plumeImages,
                    `plumes_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }
        return layers;
    }, [
        enabled,
        plumeImages,
        currentZoom,
        selectedContext,
        plumeOpacity,
        emissions,
        showPlumes,
    ]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissionLayers,
        plumeLayers,
    };
};
