/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataPackageDownloadUrl,
  DownloadableDataPackage,
  PaginatedDownloadableDataPackageList,
} from '../models/index';
import {
    DataPackageDownloadUrlFromJSON,
    DataPackageDownloadUrlToJSON,
    DownloadableDataPackageFromJSON,
    DownloadableDataPackageToJSON,
    PaginatedDownloadableDataPackageListFromJSON,
    PaginatedDownloadableDataPackageListToJSON,
} from '../models/index';

export interface DatadownloadsDataPackageDownloadUrlRetrieveRequest {
    id: string;
}

export interface DatadownloadsDataPackageListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface DatadownloadsDataPackageRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class DatadownloadsApi extends runtime.BaseAPI {

    /**
     * Generated a download URL for the file.
     */
    async datadownloadsDataPackageDownloadUrlRetrieveRaw(requestParameters: DatadownloadsDataPackageDownloadUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPackageDownloadUrl>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datadownloadsDataPackageDownloadUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/datadownloads/data-package/{id}/download_url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPackageDownloadUrlFromJSON(jsonValue));
    }

    /**
     * Generated a download URL for the file.
     */
    async datadownloadsDataPackageDownloadUrlRetrieve(requestParameters: DatadownloadsDataPackageDownloadUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPackageDownloadUrl> {
        const response = await this.datadownloadsDataPackageDownloadUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve downloadable data packages information
     */
    async datadownloadsDataPackageListRaw(requestParameters: DatadownloadsDataPackageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDownloadableDataPackageList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/datadownloads/data-package/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDownloadableDataPackageListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve downloadable data packages information
     */
    async datadownloadsDataPackageList(requestParameters: DatadownloadsDataPackageListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDownloadableDataPackageList> {
        const response = await this.datadownloadsDataPackageListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve downloadable data packages information
     */
    async datadownloadsDataPackageRetrieveRaw(requestParameters: DatadownloadsDataPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadableDataPackage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datadownloadsDataPackageRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/datadownloads/data-package/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadableDataPackageFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve downloadable data packages information
     */
    async datadownloadsDataPackageRetrieve(requestParameters: DatadownloadsDataPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadableDataPackage> {
        const response = await this.datadownloadsDataPackageRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
