/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SimpleDataPoint,
} from '../models/index';
import {
    SimpleDataPointFromJSON,
    SimpleDataPointToJSON,
} from '../models/index';

export interface DataPointsRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class DataPointsApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve public data point information.
     */
    async dataPointsRetrieveRaw(requestParameters: DataPointsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleDataPoint>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling dataPointsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/data-points/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleDataPointFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public data point information.
     */
    async dataPointsRetrieve(requestParameters: DataPointsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleDataPoint> {
        const response = await this.dataPointsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
