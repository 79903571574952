import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "d3-transition";
import { EmissionRecordView } from "../../../apiClient/generated";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { RateWithUncertaintyCell } from "../../Emissions/Extras";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faArrowUpRightFromSquare,
    faChevronLeft,
    faChevronRight,
    faSort,
    faSortDown,
    faSortUp,
} from "@fortawesome/pro-light-svg-icons";
import { DateTime } from "luxon";
import { RowActionButtons } from "../../DataTable/DataTableV3";
import { ROUTES } from "../../../routes";
import { EMISSION_COLORS } from "../../MapV2/constants";
import { useQuery } from "@tanstack/react-query";
import { useEmissionRecordsApiClient } from "../../../hooks";

const dsMap = {
    THIRD_PARTY: "thirdParty",
    SELF_REPORTED: "operatorProvided",
    EPA: "epa",
};

interface EmissionHoverProps {
    emissionId: string;
    style?: CSSProperties;
}

export const EmissionHover = (props: EmissionHoverProps) => {
    const apiClient = useEmissionRecordsApiClient();
    const emissionQuery = useQuery({
        queryKey: ["emissionRecord", props.emissionId],
        queryFn: async () => {
            const response = await apiClient.emissionRecordsRetrieve({
                id: props.emissionId,
            });
            return response;
        },
        enabled: !!props.emissionId,
        refetchOnWindowFocus: false,
    });

    return (
        <div
            className="px-2 py-3 z-50 bg-black bg-opacity-80 rounded text-white w-fit whitespace-nowrap"
            style={props.style}
        >
            {emissionQuery.data ? (
                <div>
                    <p className="font-bold mb-2">
                        {emissionQuery.data.providerName}
                    </p>
                    <p className="mb-1">
                        {DateTime.fromJSDate(
                            emissionQuery.data.dataPoint.detectionTimestamp,
                        )
                            .setZone("utc")
                            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                    </p>
                    {emissionQuery.data.dataPoint.detectedRate && (
                        <p className="mb-1">
                            {(
                                emissionQuery.data.dataPoint.detectedRate / 1000
                            ).toFixed(2)}
                            {" ± "}
                            {(
                                (emissionQuery.data.dataPoint
                                    .detectedRateUncertainty ?? 0) / 1000
                            ).toFixed(2)}
                            {" kg/h"}
                        </p>
                    )}
                    {emissionQuery.data.dataPoint.concentration && (
                        <p className="mb-1">
                            {emissionQuery.data.dataPoint.concentration.toFixed(
                                2,
                            )}
                            {" ± "}
                            {emissionQuery.data.dataPoint.concentrationUncertainty?.toFixed(
                                2,
                            )}
                            {" ppm*m"}
                        </p>
                    )}
                </div>
            ) : (
                <div className="w-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faArrowsRotate} className="w-4" />
                </div>
            )}
        </div>
    );
};

interface EmissionPlotProps {
    data: EmissionRecordView[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        provider?: Array<string>;
    };
    logScale: boolean;
    fitData: boolean;
}

export const EmissionPlot = ({
    data,
    hoverContext,
    setHoverContext,
    selectedContext,
    setSelectedContext,
    filters,
    fitData,
    logScale,
}: EmissionPlotProps) => {
    const svgRef = useRef();
    const containerRef = useRef<HTMLDivElement>();
    const [cursorPosition, setCursorPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Function to update dimensions
    const updateDimensions = useCallback(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight,
            });
        }
    }, []);

    // Effect for handling resize
    useEffect(() => {
        // Initial dimensions
        updateDimensions();

        // Add event listener for window resize
        window.addEventListener("resize", updateDimensions);

        // Create ResizeObserver for component-specific resizes
        const resizeObserver = new ResizeObserver(updateDimensions);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateDimensions);
            resizeObserver.disconnect();
        };
    }, [updateDimensions]);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const container = d3.select(containerRef.current);
        const width = container.node().clientWidth;
        const height = container.node().clientHeight;
        const margin = { top: 20, right: 5, bottom: 40, left: 50 };

        // Clear previous content
        svg.selectAll("*").remove();

        // Set up scales with padding
        let dateRange = d3.extent(data, (d) => d.dataPoint.detectionTimestamp);
        if (
            filters.detectionDateRangeAfter &&
            filters.detectionDateRangeBefore &&
            !fitData
        ) {
            dateRange = [
                new Date(filters.detectionDateRangeAfter),
                new Date(filters.detectionDateRangeBefore),
            ];
        }

        const xScale = d3
            .scaleTime()
            .domain(dateRange)
            .range([margin.left, width - margin.right])
            .nice();

        const yScaleFn = (useLogScale = true) => {
            const scale = useLogScale ? d3.scaleLog() : d3.scaleLinear();

            // Filter valid data points
            const validData = data.filter((d) => !!d.dataPoint.detectedRate);

            // Calculate min value considering uncertainty
            let minValue = d3.min(validData, (d) => {
                const uncertainty = d.dataPoint.detectedRateUncertainty || 0;
                return (d.dataPoint.detectedRate - uncertainty) / 1000;
            });

            // For log scale, ensure minimum value is at least 1
            if (useLogScale) {
                minValue = Math.max(minValue, 0.001);
            }

            // Calculate max value considering uncertainty
            const maxValue = d3.max(validData, (d) => {
                const uncertainty = d.dataPoint.detectedRateUncertainty || 0;
                return (d.dataPoint.detectedRate + uncertainty) / 1000;
            });

            return scale
                .domain([minValue, maxValue])
                .nice()
                .range([height - margin.bottom, margin.top]);
        };

        const yScaleDetection = yScaleFn(logScale);

        const computeTicks = (numberOfTicks) => {
            const [start, end] = xScale.domain();
            const interval =
                (end.getTime() - start.getTime()) / (numberOfTicks - 1);
            return Array.from(
                { length: numberOfTicks },
                (_, i) => new Date(start.getTime() + interval * i),
            );
        };

        const xAxis = d3
            .axisBottom(xScale)
            .tickSize(3)
            .tickPadding(15)
            .tickFormat((d) => {
                const timeSpanDays =
                    (dateRange[1].getTime() - dateRange[0].getTime()) /
                    (1000 * 60 * 60 * 24);
                if (timeSpanDays < 7) {
                    return d3.timeFormat("%b %d, %H:%M")(d as any);
                } else {
                    return d3.timeFormat("%b %d, %Y")(d as any);
                }
            })
            .tickValues(computeTicks(width / 120));

        const yAxisDetection = d3
            .axisLeft(yScaleDetection)
            .ticks(5, "~s")
            .tickSizeOuter(0)
            .tickPadding(10);

        // Add a background rect to handle clicks outside of points/lines
        svg.append("rect")
            .attr("x", margin.left)
            .attr("y", margin.top)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height - margin.top - margin.bottom)
            .attr("fill", "transparent")
            .on("click", () => {
                setSelectedContext();
            });

        // Add grid lines for x-axis
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(computeTicks(width / 120))
                    .tickSize(-height + margin.top + margin.bottom)
                    .tickFormat(() => ""),
            )
            .call((g) => g.select(".domain").remove())
            .selectAll("line")
            .style("stroke", "#e5e7eb")
            .style("stroke-dasharray", "3,3");

        // Add grid lines for y-axis
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(${margin.left},0)`)
            .call(
                d3
                    .axisLeft(yScaleDetection)
                    .ticks(5, "~s")
                    .tickSize(-width + margin.left + margin.right)
                    .tickFormat(() => ""),
            )
            .call((g) => g.select(".domain").remove())
            .selectAll("line")
            .style("stroke", "#e5e7eb")
            .style("stroke-dasharray", "3,3");

        // Append x-axis and style it
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .classed("text-gray-500", true)
            .call(xAxis)
            .selectAll("text")
            .style("font-size", "14px");

        // Append y-axis and style it
        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .classed("text-gray-500", true)
            .call(yAxisDetection)
            .selectAll("text")
            .style("font-size", "14px");

        // Add y-axis label at the bottom
        svg.append("text")
            .classed("text-gray-500", true)
            .attr("x", margin.left)
            .attr("y", margin.top - 10)
            .attr("text-anchor", "middle")
            .style("font-size", "14px")
            .text("kg/hr");

        // Add error bars and points as before
        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(data)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        // Add horizontal caps to the error bars (T-shape)
        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(data)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp) - 5)
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp) + 5)
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(data)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp) - 5)
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp) + 5)
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        // First, define a pattern for elements without infrastructure
        svg.append("defs")
            .selectAll("pattern")
            .data(Object.entries(EMISSION_COLORS))
            .enter()
            .append("pattern")
            .attr("id", ([key]) => `noInfrastructurePattern-${key}`)
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", 4)
            .attr("height", 4)
            .each(function ([, color]) {
                // Add white background rectangle
                d3.select(this)
                    .append("rect")
                    .attr("width", 4)
                    .attr("height", 4)
                    .attr("fill", "white");

                // Add the diagonal lines pattern
                d3.select(this)
                    .append("path")
                    .attr("d", "M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2")
                    .attr("stroke", `rgb(${color.join(",")})`)
                    .attr("stroke-width", 1);
            });
        // Create a group for both the visible line and hover area
        const concentrationLines = svg
            .append("g")
            .selectAll("g")
            .data(data)
            .enter()
            .filter(
                (d) =>
                    d.dataPoint.concentration !== undefined &&
                    !d.dataPoint.detectedRate,
            )
            .append("g");

        // Add the visible dashed line
        concentrationLines
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", margin.top)
            .attr("y2", height - margin.bottom)
            .attr("stroke", (d) => {
                const color = EMISSION_COLORS[dsMap[d.dataPoint.dataSource]];
                return color ? `rgb(${color.join(",")})` : "#000";
            })
            .attr("stroke-width", (d) => {
                if (selectedContext) {
                    return selectedContext === d.id ? 2 : 1;
                }
                if (hoverContext) {
                    return hoverContext === d.id ? 2 : 1;
                }
                return 1;
            })
            .attr("stroke-dasharray", (d) => (d.infrastructure ? "6,6" : "2,4"))
            .style("opacity", 0.5);

        // Add the invisible wider line for easier hovering
        concentrationLines
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", margin.top)
            .attr("y2", height - margin.bottom)
            .attr("stroke", (d) => {
                if (selectedContext) {
                    return selectedContext === d.id
                        ? "#00000010"
                        : "transparent";
                }
                if (hoverContext) {
                    return hoverContext === d.id ? "#00000010" : "transparent";
                }
                return "transparent";
            })
            .attr("stroke-width", 10)
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                setHoverContext(d.id);
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: event.clientX - containerRect.left,
                    y: event.clientY - containerRect.top,
                });
            })
            .on("mouseout", () => {
                setHoverContext();
                setCursorPosition(null);
            })
            .on("click", (event, d) => {
                // Prevent background click from triggering
                event.stopPropagation();
                setSelectedContext(selectedContext === d.id ? undefined : d.id);
            });

        // Add scatterplot points as rectangles
        svg.append("g")
            .selectAll("circle")
            .data(data)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("circle")
            .attr("cx", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("cy", (d) => yScaleDetection(d.dataPoint.detectedRate / 1000))
            .attr("r", (d) =>
                selectedContext === d.id ||
                (!selectedContext && hoverContext === d.id)
                    ? 7
                    : 5,
            )
            .attr("fill", (d) => {
                const dataSource = dsMap[d.dataPoint.dataSource];
                if (!d.infrastructure) {
                    return `url(#noInfrastructurePattern-${dataSource})`;
                }
                const color = EMISSION_COLORS[dataSource];
                return color ? `rgb(${color.join(",")})` : "#000";
            })
            .attr("stroke", (d) => {
                if (
                    selectedContext === d.id ||
                    (!selectedContext && hoverContext === d.id)
                ) {
                    return "red";
                }
                if (!d.infrastructure) {
                    const color =
                        EMISSION_COLORS[dsMap[d.dataPoint.dataSource]];
                    return color ? `rgb(${color.join(",")})` : "#000";
                }
                return "transparent";
            })
            .attr("stroke-width", (d) => {
                if (
                    selectedContext === d.id ||
                    (!selectedContext && hoverContext === d.id)
                ) {
                    return 2;
                }
                return d.infrastructure ? 0 : 1;
            })
            .on("mouseover", (event, d) => {
                setHoverContext(d.id);
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: event.clientX - containerRect.left,
                    y: event.clientY - containerRect.top,
                });
            })
            .on("mouseout", () => {
                setHoverContext();
                setCursorPosition(null);
            })
            .on("click", (event, d) => {
                event.stopPropagation();
                setSelectedContext(selectedContext === d.id ? undefined : d.id);
            });
    }, [
        data,
        hoverContext,
        setHoverContext,
        selectedContext,
        setSelectedContext,
        filters,
        logScale,
        fitData,
        dimensions,
    ]);

    return (
        <div className="px-4">
            <div
                ref={containerRef}
                className="w-full h-[400px] relative"
                onMouseMove={(e) =>
                    setCursorPosition({ x: e.clientX, y: e.clientY })
                }
                onMouseLeave={() => setCursorPosition(null)}
            >
                {hoverContext && cursorPosition && (
                    <EmissionHover
                        emissionId={hoverContext}
                        style={{
                            position: "fixed",
                            left: cursorPosition.x + 100,
                            top: cursorPosition.y + 15,
                            pointerEvents: "none",
                            transform: "translate(-50%, 0)",
                        }}
                    />
                )}
                <svg
                    ref={svgRef}
                    style={{ width: "100%", height: "100%" }}
                ></svg>
            </div>
            <div className="h-full flex justify-center text-sm text-neutral-500">
                <div className="rounded px-4 py-2 flex items-center justify-center gap-6 mb-2 border bg-neutral-50/2 border-neutral-200">
                    <div className="flex items-center gap-2">
                        <div
                            className="h-[5px] w-3 rounded-sm"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.epa})`,
                            }}
                        />
                        EPA Super Emitter Program
                    </div>
                    <div className="flex items-center gap-2">
                        <div
                            className="h-[5px] w-3 rounded-sm"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.operatorProvided})`,
                            }}
                        />
                        Company Monitoring Data
                    </div>
                    <div className="flex items-center gap-2">
                        <div
                            className="h-[5px] w-3 rounded-sm"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.thirdParty})`,
                            }}
                        />
                        3rd Party Detections
                    </div>
                </div>
            </div>
        </div>
    );
};

interface EmissionsTableProps {
    emissions: EmissionRecordView[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
}

const columnHelper = createColumnHelper<EmissionRecordView>();
const columns = [
    columnHelper.accessor("dataPoint.detectionTimestamp", {
        id: "detectionTimestamp",
        cell: ({ getValue }) =>
            DateTime.fromJSDate(getValue())
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_MED),
        header: "Detected at (UTC)",
        sortingFn: (rowA, rowB) => {
            return (
                rowA.original.dataPoint.detectionTimestamp.getTime() -
                rowB.original.dataPoint.detectionTimestamp.getTime()
            );
        },
    }),
    columnHelper.accessor("providerName", {
        id: "providerName",
        header: () => "Source",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(
        (row) => {
            return {
                concentration: row.dataPoint?.concentration,
                concentrationUncertainty:
                    row.dataPoint?.concentrationUncertainty,
            };
        },
        {
            id: "concentration",
            header: "Concentration",
            cell: (info) => {
                const { concentration, concentrationUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={concentration}
                        uncertainty={concentrationUncertainty}
                        unit="ppm*m"
                    />
                );
            },
            sortingFn: (rowA, rowB) => {
                return (
                    rowA.original.dataPoint.concentration -
                    rowB.original.dataPoint.concentration
                );
            },
        },
    ),
    columnHelper.accessor(
        (row) => {
            return {
                detectedRate: row.dataPoint?.detectedRate,
                detectedRateUncertainty: row.dataPoint?.detectedRateUncertainty,
            };
        },
        {
            id: "detectedRate",
            header: "Detected Rate",
            cell: (info) => {
                const { detectedRate, detectedRateUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={detectedRate ? detectedRate / 1000 : detectedRate}
                        uncertainty={
                            detectedRateUncertainty
                                ? detectedRateUncertainty / 1000
                                : detectedRateUncertainty
                        }
                        unit="kg/h"
                    />
                );
            },
            sortingFn: (rowA, rowB) => {
                return (
                    rowA.original.dataPoint.detectedRate -
                    rowB.original.dataPoint.detectedRate
                );
            },
        },
    ),
    columnHelper.display({
        id: "actions",
        header: "",
        enableColumnFilter: false,
        cell: (props) => {
            const actions = [
                {
                    fn: () => {
                        const url =
                            props.row.original.dataPoint.dataSource ===
                            "SELF_REPORTED"
                                ? ROUTES.DASHBOARD_SELF_REPORTED
                                : ROUTES.DASHBOARD_THIRD_PARTY;
                        window.open(
                            `${url}?selectedEvent=${props.row.original.id}`,
                            "_blank",
                        );
                    },
                    icon: (
                        <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className="w-3"
                        />
                    ),
                    tooltip: "Manage Emission",
                },
            ];

            return <RowActionButtons actions={actions} />;
        },
    }),
];

export const EmissionsTable = (props: EmissionsTableProps) => {
    const table = useReactTable({
        data: props.emissions,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="h-full flex flex-col text-sm whitespace-nowrap">
            <div>
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="cursor-pointer px-4 text-left h-10 bg-gray-100"
                                    >
                                        <div className="flex items-center justify-between">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            {{
                                                asc: (
                                                    <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        className="w-4"
                                                    />
                                                ),
                                                desc: (
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        className="w-4"
                                                    />
                                                ),
                                            }[
                                                header.column.getIsSorted() as string
                                            ] ??
                                            header.column.getCanFilter() ? (
                                                <FontAwesomeIcon
                                                    icon={faSort}
                                                    className="w-4"
                                                />
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={`h-10 px-4 ${
                                            props.selectedContext ===
                                            row.original.id
                                                ? "bg-gray-300"
                                                : props.hoverContext ===
                                                    row.original.id
                                                  ? "bg-gray-200"
                                                  : ""
                                        }`}
                                        onMouseEnter={() =>
                                            props.setHoverContext(
                                                row.original.id,
                                            )
                                        }
                                        onMouseLeave={() =>
                                            props.setHoverContext()
                                        }
                                        onClick={() => {
                                            props.setSelectedContext(
                                                props.selectedContext ===
                                                    row.original.id
                                                    ? undefined
                                                    : row.original.id,
                                            );
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="py-2 px-4 border-t flex items-center justify-between">
                <div>{props.emissions.length} items</div>
                <div className="flex items-center">
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className="px-2 py-1 mr-1 border border-transparent disabled:text-gray-400 disabled:border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550 rounded"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="w-4" />
                    </button>
                    <span className="mx-2">
                        Page{" "}
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                        </strong>
                    </span>
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className="px-2 py-1 mr-1 border border-transparent disabled:text-gray-400 disabled:border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550 rounded"
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="w-4"
                        />
                    </button>
                </div>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                    }}
                    className="ml-2 p-1 w-32 border rounded text-sm"
                >
                    {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
