/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
  EmissionEventFull,
  EmissionEventUpdate,
  EmissionEventUpdateRequest,
  EventStatsResponse,
  PaginatedEmissionEventFullList,
  PatchedEmissionEventUpdateRequest,
} from '../models/index';
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInnerFromJSON,
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInnerToJSON,
    EmissionEventFullFromJSON,
    EmissionEventFullToJSON,
    EmissionEventUpdateFromJSON,
    EmissionEventUpdateToJSON,
    EmissionEventUpdateRequestFromJSON,
    EmissionEventUpdateRequestToJSON,
    EventStatsResponseFromJSON,
    EventStatsResponseToJSON,
    PaginatedEmissionEventFullListFromJSON,
    PaginatedEmissionEventFullListToJSON,
    PatchedEmissionEventUpdateRequestFromJSON,
    PatchedEmissionEventUpdateRequestToJSON,
} from '../models/index';

export interface EmissioneventsEventStatsRetrieveRequest {
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissioneventsEventStatsRetrieveDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    eventStatus?: Array<EmissioneventsEventStatsRetrieveEventStatusEnum>;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    provider?: Array<string>;
    providerWithSource?: Array<AdminEmissionsRecordsStatsListProviderWithSourceParameterInner>;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissioneventsListRequest {
    assignedTo?: Array<string>;
    concentrationMax?: number;
    concentrationMin?: number;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<EmissioneventsListDataSourceEnum>;
    detectedRateMax?: number;
    detectedRateMin?: number;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    eventStatus?: Array<EmissioneventsListEventStatusEnum>;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    provider?: Array<string>;
    providerWithSource?: Array<AdminEmissionsRecordsStatsListProviderWithSourceParameterInner>;
    search?: string;
    secondaryDataSource?: Array<string>;
}

export interface EmissioneventsPartialUpdateRequest {
    id: string;
    patchedEmissionEventUpdateRequest?: PatchedEmissionEventUpdateRequest;
}

export interface EmissioneventsRetrieveRequest {
    id: string;
}

export interface EmissioneventsUpdateRequest {
    id: string;
    emissionEventUpdateRequest: EmissionEventUpdateRequest;
}

/**
 * 
 */
export class EmissioneventsApi extends runtime.BaseAPI {

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async emissioneventsEventStatsRetrieveRaw(requestParameters: EmissioneventsEventStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventStatsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['providerWithSource'] != null) {
            queryParameters['provider_with_source'] = requestParameters['providerWithSource'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emissionevents/event_stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventStatsResponseFromJSON(jsonValue));
    }

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async emissioneventsEventStatsRetrieve(requestParameters: EmissioneventsEventStatsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventStatsResponse> {
        const response = await this.emissioneventsEventStatsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsListRaw(requestParameters: EmissioneventsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEmissionEventFullList>> {
        const queryParameters: any = {};

        if (requestParameters['assignedTo'] != null) {
            queryParameters['assigned_to'] = requestParameters['assignedTo'];
        }

        if (requestParameters['concentrationMax'] != null) {
            queryParameters['concentration_max'] = requestParameters['concentrationMax'];
        }

        if (requestParameters['concentrationMin'] != null) {
            queryParameters['concentration_min'] = requestParameters['concentrationMin'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectedRateMax'] != null) {
            queryParameters['detected_rate_max'] = requestParameters['detectedRateMax'];
        }

        if (requestParameters['detectedRateMin'] != null) {
            queryParameters['detected_rate_min'] = requestParameters['detectedRateMin'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['providerWithSource'] != null) {
            queryParameters['provider_with_source'] = requestParameters['providerWithSource'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emissionevents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEmissionEventFullListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsList(requestParameters: EmissioneventsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEmissionEventFullList> {
        const response = await this.emissioneventsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsPartialUpdateRaw(requestParameters: EmissioneventsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionEventUpdate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissioneventsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emissionevents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEmissionEventUpdateRequestToJSON(requestParameters['patchedEmissionEventUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionEventUpdateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsPartialUpdate(requestParameters: EmissioneventsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionEventUpdate> {
        const response = await this.emissioneventsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsRetrieveRaw(requestParameters: EmissioneventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionEventFull>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissioneventsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emissionevents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionEventFullFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsRetrieve(requestParameters: EmissioneventsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionEventFull> {
        const response = await this.emissioneventsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsUpdateRaw(requestParameters: EmissioneventsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionEventUpdate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling emissioneventsUpdate().'
            );
        }

        if (requestParameters['emissionEventUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'emissionEventUpdateRequest',
                'Required parameter "emissionEventUpdateRequest" was null or undefined when calling emissioneventsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/emissionevents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmissionEventUpdateRequestToJSON(requestParameters['emissionEventUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionEventUpdateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve Emission Event information.  TODO: rework how state changes are handled (use a PATCH event with custom validation).
     */
    async emissioneventsUpdate(requestParameters: EmissioneventsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionEventUpdate> {
        const response = await this.emissioneventsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const EmissioneventsEventStatsRetrieveDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissioneventsEventStatsRetrieveDataSourceEnum = typeof EmissioneventsEventStatsRetrieveDataSourceEnum[keyof typeof EmissioneventsEventStatsRetrieveDataSourceEnum];
/**
 * @export
 */
export const EmissioneventsEventStatsRetrieveEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissioneventsEventStatsRetrieveEventStatusEnum = typeof EmissioneventsEventStatsRetrieveEventStatusEnum[keyof typeof EmissioneventsEventStatsRetrieveEventStatusEnum];
/**
 * @export
 */
export const EmissioneventsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type EmissioneventsListDataSourceEnum = typeof EmissioneventsListDataSourceEnum[keyof typeof EmissioneventsListDataSourceEnum];
/**
 * @export
 */
export const EmissioneventsListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type EmissioneventsListEventStatusEnum = typeof EmissioneventsListEventStatusEnum[keyof typeof EmissioneventsListEventStatusEnum];
