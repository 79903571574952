import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import {
    AccountsApi,
    AdminApi,
    AuthApi,
    DatauploadApi,
    EmissionRecordsApi,
    InfrastructureApi,
    MapApi,
    EmissionEventsApi,
    RootCausesApi,
    DatadownloadsApi,
    DataProvidersApi,
    DataImportApi,
    NotificationsApi,
    DataPointsApi,
    GeodataApi,
} from "./apiClient";
import { useEffect, useMemo } from "react";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAccountsApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => AccountsApi(token!), [token]);
};

export const useAuthApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => AuthApi(token!), [token]);
};

export const useDatauploadApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => DatauploadApi(token!), [token]);
};

export const useDatadownloadsApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => DatadownloadsApi(token!), [token]);
};

export const useEmissionRecordsApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => EmissionRecordsApi(token!), [token]);
};

export const useInfrastructureApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => InfrastructureApi(token!), [token]);
};

export const useDataImportApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => DataImportApi(token!), [token]);
};

export const useMapApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => MapApi(token!), [token]);
};

export const useEmissionEventsApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => EmissionEventsApi(token!), [token]);
};

export const useRootCausesApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => RootCausesApi(token!), [token]);
};

export const useDataProvidersApi = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => DataProvidersApi(token!), [token]);
};

export const useNotificationsApi = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => NotificationsApi(token!), [token]);
};

export const useDataPointsApi = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => DataPointsApi(token!), [token]);
};

export const useAdminApiClient = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => AdminApi(token!), [token]);
};

export const useGeoDataApi = () => {
    const token = useAppSelector((state) => state.auth.token);
    return useMemo(() => GeodataApi(token!), [token]);
};

// Custom hook to deselect things
// TODO: replace with the `use-hooks` version.
export const useOnClickAway = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (evt) => {
            if (ref.current && !ref.current.contains(evt.target)) {
                callback();
            }
        };
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    });
};
