import { useQuery } from "@tanstack/react-query";
import {
    useEmissionRecordsApiClient,
    useInfrastructureApiClient,
} from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faExpand } from "@fortawesome/pro-light-svg-icons";
import { DateRangeField } from "../../../ui/Inputs";
import { useEffect, useMemo, useState } from "react";
import {
    Infrastructure,
    EmissionRecordView,
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
} from "../../../apiClient/generated";
import { EmissionPlot, EmissionsTable } from "./Emissions";
import { InfrastructureDetailMap } from "./Map";
import { SidePanel } from "./SidePanel";
import { InputButton, SecondaryButton } from "../../../ui/Buttons";
import { FullscreenModal } from "../../../ui/Modals";
import { DataExportStatus } from "../../dashboards/DataExport";
import { DataProviderDropdown } from "../../filters/ProviderFilter";
import { CoordinatesField } from "../../../ui/GenericFields";
import { MapPlotToggle, SettingsDropdown } from "./Ui";
import { useSearchParams } from "react-router-dom";

interface SectionProps {
    infrastructureId: string;
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    };
    infrastructure: Infrastructure[];
    emissions: EmissionRecordView[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
}

export const EmissionPlotSection = (props: SectionProps) => {
    const [logScale, setLogScale] = useState(true);

    return (
        <div className="flex flex-col overflow-hidden w-full">
            <div className="flex items-center justify-between px-4 pt-4 pb-2">
                <p className="text-md font-semibold">Emissions Plot</p>
                <div className="flex items-center gap-4 text-sm">
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={logScale}
                            onChange={() => setLogScale((prev) => !prev)}
                            className="form-checkbox h-4 w-4 rounded"
                        />
                        <span>Use logarithmic scale</span>
                    </label>
                </div>
            </div>
            {props.emissions.length > 0 ? (
                <EmissionPlot
                    data={props.emissions || []}
                    selectedContext={props.selectedContext}
                    setSelectedContext={props.setSelectedContext}
                    hoverContext={props.hoverContext}
                    setHoverContext={props.setHoverContext}
                    filters={props.filters}
                    fitData={false}
                    logScale={logScale}
                />
            ) : (
                <div className="px-4">
                    No emission data for the selected filters.
                </div>
            )}
        </div>
    );
};

export const EmissionTableSection = (props: SectionProps) => {
    const apiClient = useEmissionRecordsApiClient();
    const [exportRequested, setExportRequested] = useState(false);
    const [exportId, setExportId] = useState<string>(undefined);

    const onSubmit = async (data) => {
        setExportRequested(true);
        try {
            const response =
                await apiClient.emissionRecordsGenerateCsvReportCreate({
                    providerWithSource: props.filters.providerWithSource
                        ? props.filters.providerWithSource
                        : undefined,
                    detectionDateRangeAfter: props.filters
                        .detectionDateRangeAfter
                        ? new Date(props.filters.detectionDateRangeAfter)
                        : undefined,
                    detectionDateRangeBefore: props.filters
                        .detectionDateRangeBefore
                        ? new Date(props.filters.detectionDateRangeBefore)
                        : undefined,
                    infrastructure: props.infrastructureId,
                });
            if (response.status === "no_items") {
                alert("No emission record data found for selected filters.");
            } else if (response.status === "already_in_progress") {
                alert(
                    "You already requested a data export for the given filters.",
                );
            } else {
                setExportId(response.dataDownloadId);
            }
        } catch (e) {
            let errorMsg = "Error while generating report. ";
            if (data.locationWithin) {
                errorMsg +=
                    " Reduce the complexity/size of the attached file and try again.";
            }
            alert(errorMsg);
        }
        setExportRequested(false);
    };

    return (
        <div className="flex flex-col overflow-hidden w-full">
            <div className="flex items-center justify-between px-4 pt-4 pb-2">
                <p className="text-md font-semibold">Emissions Table</p>
                <SecondaryButton
                    variant="lg"
                    onClick={onSubmit}
                    disabled={exportRequested}
                >
                    {exportRequested ? (
                        <FontAwesomeIcon
                            icon={faArrowsRotate}
                            className="w-4 animate-spin"
                        />
                    ) : (
                        "Export as CSV"
                    )}
                </SecondaryButton>
            </div>
            {props.emissions.length > 0 ? (
                <EmissionsTable
                    emissions={props.emissions}
                    selectedContext={props.selectedContext}
                    setSelectedContext={props.setSelectedContext}
                    hoverContext={props.hoverContext}
                    setHoverContext={props.setHoverContext}
                />
            ) : (
                <div className="px-4">
                    No emission data for the selected filters.
                </div>
            )}
            <FullscreenModal
                visible={!!exportId}
                onClose={() => setExportId(undefined)}
                title="Export Emission Data as CSV"
            >
                <DataExportStatus
                    exportId={exportId}
                    onClose={() => setExportId(undefined)}
                />
            </FullscreenModal>
        </div>
    );
};

const Loading = () => (
    <div className="w-full h-80 flex flex-col items-center justify-center">
        <FontAwesomeIcon icon={faArrowsRotate} className="h-10 animate-spin" />
    </div>
);

interface InfrastructureDetailViewProps {
    infrastructureId?: string;
}

export const InfrastructureDetailView = (
    props: InfrastructureDetailViewProps,
) => {
    const [urlParams, setSearchParams] = useSearchParams();
    // Start and end date ranges
    const initialDateRange = useMemo(() => {
        let initialAfterDate = undefined;
        if (urlParams.get("detectionDateRangeAfter")) {
            const date = new Date(urlParams.get("detectionDateRangeAfter"));
            if (!Number.isNaN(date.getTime())) {
                initialAfterDate = date.toISOString();
            }
        }
        let initialBeforeDate = undefined;
        if (urlParams.get("detectionDateRangeBefore")) {
            const date = new Date(urlParams.get("detectionDateRangeBefore"));
            if (!Number.isNaN(date.getTime())) {
                initialBeforeDate = date.toISOString();
            }
        }
        return [initialAfterDate, initialBeforeDate];
    }, [urlParams]);

    // Emission ID from URL
    const initialSelectedContext = useMemo(() => {
        return urlParams.get("selectedContext");
    }, [urlParams]);

    // Clear parameters from the URL
    useEffect(() => {
        if (
            urlParams.get("selectedContext") ||
            urlParams.get("detectionDateRangeAfter") ||
            urlParams.get("detectionDateRangeBefore")
        ) {
            setSearchParams(new URLSearchParams());
        }
    }, [urlParams]);

    const [filters, setFilters] = useState<{
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    }>({
        detectionDateRangeAfter: initialDateRange[0]
            ? initialDateRange[0]
            : new Date(
                  new Date().setMonth(new Date().getMonth() - 6),
              ).toISOString(),
        detectionDateRangeBefore: initialDateRange[1]
            ? initialDateRange[1]
            : new Date().toISOString(),
    });
    const [hoverContext, setHoverContext] = useState<string>();
    const [selectedContext, setSelectedContext] = useState<string>(
        initialSelectedContext,
    );
    const [expanded, setExpanded] = useState<"MAP" | "PLOT">(undefined);
    const [enabledData, setEnabledData] = useState([
        "EMISSIONS",
        "TENTATIVE_EMISSIONS",
    ]);

    // Retrieve infrastructure data
    const apiClient = useInfrastructureApiClient();
    const infrastructureQuery = useQuery({
        queryKey: ["infrastructure", props.infrastructureId],
        queryFn: async () => {
            const response = await apiClient.infrastructureListRetrieve({
                id: props.infrastructureId,
            });
            return response;
        },
        enabled: !!props.infrastructureId,
    });

    const treeQuery = useQuery({
        queryKey: ["infrastructureTree", props.infrastructureId],
        queryFn: async () => {
            const response = await apiClient.infrastructureListList({
                parent: [props.infrastructureId],
                pageSize: 100,
            });
            return response.results;
        },
        enabled: !!props.infrastructureId,
    });

    // Retrieve emission data
    const emissionRecordsApiClient = useEmissionRecordsApiClient();
    const emissionRecordsQuery = useQuery({
        queryKey: [
            "infrastructureEmissionRecords",
            props.infrastructureId,
            filters,
        ],
        queryFn: async () => {
            const response = await emissionRecordsApiClient.emissionRecordsList(
                {
                    infrastructure: props.infrastructureId,
                    providerWithSource:
                        filters.providerWithSource &&
                        filters.providerWithSource.length > 0
                            ? (JSON.stringify(
                                  filters.providerWithSource,
                              ) as any)
                            : undefined,
                    detectionDateRangeAfter: filters.detectionDateRangeAfter
                        ? new Date(filters.detectionDateRangeAfter)
                        : undefined,
                    detectionDateRangeBefore: filters.detectionDateRangeBefore
                        ? new Date(filters.detectionDateRangeBefore)
                        : undefined,
                    pageSize: 200,
                },
            );
            return response.results.filter(
                (i) => i.dataPoint.detectedRate || i.dataPoint.concentration,
            );
        },
        enabled: !!props.infrastructureId && enabledData.includes("EMISSIONS"),
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const tentativeEmissionRecordsQuery = useQuery({
        queryKey: [
            "infrastructureTentativeEmissionRecords",
            props.infrastructureId,
            filters,
        ],
        queryFn: async () => {
            const response = await emissionRecordsApiClient.emissionRecordsList(
                {
                    tentativeInfrastructure: props.infrastructureId,
                    providerWithSource:
                        filters.providerWithSource &&
                        filters.providerWithSource.length > 0
                            ? (JSON.stringify(
                                  filters.providerWithSource,
                              ) as any)
                            : undefined,
                    detectionDateRangeAfter: filters.detectionDateRangeAfter
                        ? new Date(filters.detectionDateRangeAfter)
                        : undefined,
                    detectionDateRangeBefore: filters.detectionDateRangeBefore
                        ? new Date(filters.detectionDateRangeBefore)
                        : undefined,
                    pageSize: 200,
                },
            );
            return response.results.filter(
                (i) => i.dataPoint.detectedRate || i.dataPoint.concentration,
            );
        },
        enabled:
            !!props.infrastructureId &&
            enabledData.includes("TENTATIVE_EMISSIONS"),
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const emissions = useMemo(() => {
        let data = [];
        if (enabledData.includes("EMISSIONS") && emissionRecordsQuery.data) {
            data = data.concat(emissionRecordsQuery.data);
        }
        if (
            enabledData.includes("TENTATIVE_EMISSIONS") &&
            tentativeEmissionRecordsQuery.data
        ) {
            data = data.concat(tentativeEmissionRecordsQuery.data);
        }
        return data;
    }, [emissionRecordsQuery, tentativeEmissionRecordsQuery, enabledData]);

    const loading =
        !emissionRecordsQuery.data ||
        !tentativeEmissionRecordsQuery.data ||
        !treeQuery.data ||
        !infrastructureQuery.data;

    return (
        <div className="flex flex-col gap-3 px-6 py-8 h-screen text-sm overflow-scroll relative">
            <div>
                <p className="font-bold text-xl mb-1">
                    Site: {infrastructureQuery.data?.siteName}
                    {infrastructureQuery.data?.extraData[
                        "Operator Unique ID 1"
                    ] &&
                        ` (${infrastructureQuery.data?.extraData["Operator Unique ID 1"]})`}
                </p>
                <p className="flex items-center gap-1">
                    Location:{" "}
                    <CoordinatesField
                        coordinates={
                            infrastructureQuery.data?.location.coordinates
                        }
                        allowCopy
                    />
                </p>
            </div>

            <SidePanel
                infrastructure={infrastructureQuery.data}
                treeData={treeQuery.data}
            />

            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <DateRangeField
                        valueAfter={filters.detectionDateRangeAfter}
                        onChangeAfter={(v) =>
                            setFilters((f) => ({
                                ...f,
                                detectionDateRangeAfter: v,
                            }))
                        }
                        valueBefore={filters.detectionDateRangeBefore}
                        onChangeBefore={(v) =>
                            setFilters((f) => ({
                                ...f,
                                detectionDateRangeBefore: v,
                            }))
                        }
                        showPresets
                        showRangeNavigation
                    />
                    <InputButton
                        className="h-8 px-2 rounded border flex gap-2 items-center justify-center overflow-hidden"
                        onClick={() => {
                            const emissionDates = emissions.map((i) =>
                                i.dataPoint.detectionTimestamp.getTime(),
                            );

                            // Find min and max dates from data we have on screen
                            const minDate = new Date(
                                Math.min(...emissionDates),
                            );
                            const maxDate = new Date(
                                Math.max(...emissionDates),
                            );

                            // Set dates
                            setFilters((f) => ({
                                ...f,
                                detectionDateRangeAfter: new Date(
                                    minDate,
                                ).toISOString(),
                                detectionDateRangeBefore: new Date(
                                    maxDate,
                                ).toISOString(),
                            }));
                        }}
                        disabled={emissionRecordsQuery.data?.length === 0}
                    >
                        Fit date range
                        <FontAwesomeIcon icon={faExpand} />
                    </InputButton>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-64">
                        <DataProviderDropdown
                            filterValue={filters.providerWithSource}
                            setFilterValue={(v) =>
                                setFilters((f) => ({
                                    ...f,
                                    providerWithSource: v ? v : undefined,
                                }))
                            }
                        />
                    </div>
                    <MapPlotToggle
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                    <SettingsDropdown
                        value={enabledData}
                        setValue={setEnabledData}
                        options={[
                            {
                                id: "EMISSIONS",
                                label: "Emissions",
                            },
                            {
                                id: "TENTATIVE_EMISSIONS",
                                label: "Tentative emissions",
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="flex gap-2">
                {(expanded === "PLOT" || !expanded) && (
                    <div className="bg-white w-full rounded-md border-slate-200 border border-solid">
                        {loading ? (
                            <Loading />
                        ) : (
                            <EmissionPlotSection
                                emissions={emissions}
                                infrastructure={treeQuery.data}
                                infrastructureId={props.infrastructureId}
                                filters={filters}
                                hoverContext={hoverContext}
                                setHoverContext={setHoverContext}
                                selectedContext={selectedContext}
                                setSelectedContext={setSelectedContext}
                            />
                        )}
                    </div>
                )}
                {(expanded === "MAP" || !expanded) && (
                    <div className="flex flex-col w-full bg-white rounded-md border-slate-200 border border-solid">
                        {loading ? (
                            <Loading />
                        ) : (
                            <div className="flex h-[500px] gap-4">
                                <InfrastructureDetailMap
                                    infrastructureId={props.infrastructureId}
                                    filters={filters}
                                    enabledData={enabledData}
                                    hoverContext={hoverContext}
                                    setHoverContext={setHoverContext}
                                    selectedContext={selectedContext}
                                    setSelectedContext={setSelectedContext}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="flex flex-col w-full bg-white rounded-md border-slate-200 border border-solid">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="flex gap-4">
                        <EmissionTableSection
                            emissions={emissions}
                            infrastructure={treeQuery.data}
                            infrastructureId={props.infrastructureId}
                            filters={filters}
                            hoverContext={hoverContext}
                            setHoverContext={setHoverContext}
                            selectedContext={selectedContext}
                            setSelectedContext={setSelectedContext}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
