/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminInfrastructureImport,
  AdminInfrastructureImportItem,
  AdminInfrastructureImportItemRequest,
  AdminInfrastructureImportRequest,
  Infrastructure,
  InfrastructureConflict,
  InfrastructureImport,
  InfrastructureImportFileUploadRequest,
  InfrastructureImportItem,
  InfrastructureImportItemCreate,
  InfrastructureImportItemCreateRequest,
  InfrastructureImportItemRequest,
  InfrastructureImportPresignedUploadUrl,
  InfrastructureImportRequest,
  InfrastructureMatch,
  MergeSimilarRowRequest,
  PaginatedAdminInfrastructureImportItemList,
  PaginatedAdminInfrastructureImportList,
  PaginatedInfrastructureImportItemList,
  PaginatedInfrastructureImportList,
  PaginatedInfrastructureList,
  PaginatedPipelineList,
  PaginatedPipelineSegmentList,
  PatchedAdminInfrastructureImportItemRequest,
  PatchedAdminInfrastructureImportRequest,
  PatchedInfrastructureImportItemRequest,
  PatchedInfrastructureImportRequest,
  Pipeline,
  PipelineSegment,
} from '../models/index';
import {
    AdminInfrastructureImportFromJSON,
    AdminInfrastructureImportToJSON,
    AdminInfrastructureImportItemFromJSON,
    AdminInfrastructureImportItemToJSON,
    AdminInfrastructureImportItemRequestFromJSON,
    AdminInfrastructureImportItemRequestToJSON,
    AdminInfrastructureImportRequestFromJSON,
    AdminInfrastructureImportRequestToJSON,
    InfrastructureFromJSON,
    InfrastructureToJSON,
    InfrastructureConflictFromJSON,
    InfrastructureConflictToJSON,
    InfrastructureImportFromJSON,
    InfrastructureImportToJSON,
    InfrastructureImportFileUploadRequestFromJSON,
    InfrastructureImportFileUploadRequestToJSON,
    InfrastructureImportItemFromJSON,
    InfrastructureImportItemToJSON,
    InfrastructureImportItemCreateFromJSON,
    InfrastructureImportItemCreateToJSON,
    InfrastructureImportItemCreateRequestFromJSON,
    InfrastructureImportItemCreateRequestToJSON,
    InfrastructureImportItemRequestFromJSON,
    InfrastructureImportItemRequestToJSON,
    InfrastructureImportPresignedUploadUrlFromJSON,
    InfrastructureImportPresignedUploadUrlToJSON,
    InfrastructureImportRequestFromJSON,
    InfrastructureImportRequestToJSON,
    InfrastructureMatchFromJSON,
    InfrastructureMatchToJSON,
    MergeSimilarRowRequestFromJSON,
    MergeSimilarRowRequestToJSON,
    PaginatedAdminInfrastructureImportItemListFromJSON,
    PaginatedAdminInfrastructureImportItemListToJSON,
    PaginatedAdminInfrastructureImportListFromJSON,
    PaginatedAdminInfrastructureImportListToJSON,
    PaginatedInfrastructureImportItemListFromJSON,
    PaginatedInfrastructureImportItemListToJSON,
    PaginatedInfrastructureImportListFromJSON,
    PaginatedInfrastructureImportListToJSON,
    PaginatedInfrastructureListFromJSON,
    PaginatedInfrastructureListToJSON,
    PaginatedPipelineListFromJSON,
    PaginatedPipelineListToJSON,
    PaginatedPipelineSegmentListFromJSON,
    PaginatedPipelineSegmentListToJSON,
    PatchedAdminInfrastructureImportItemRequestFromJSON,
    PatchedAdminInfrastructureImportItemRequestToJSON,
    PatchedAdminInfrastructureImportRequestFromJSON,
    PatchedAdminInfrastructureImportRequestToJSON,
    PatchedInfrastructureImportItemRequestFromJSON,
    PatchedInfrastructureImportItemRequestToJSON,
    PatchedInfrastructureImportRequestFromJSON,
    PatchedInfrastructureImportRequestToJSON,
    PipelineFromJSON,
    PipelineToJSON,
    PipelineSegmentFromJSON,
    PipelineSegmentToJSON,
} from '../models/index';

export interface InfrastructureAdminImportApproveAndImportDataCreateRequest {
    id: string;
}

export interface InfrastructureAdminImportCreateRequest {
    adminInfrastructureImportRequest: AdminInfrastructureImportRequest;
}

export interface InfrastructureAdminImportDestroyRequest {
    id: string;
}

export interface InfrastructureAdminImportItemsCreateRequest {
    infrastructureImportItemCreateRequest: InfrastructureImportItemCreateRequest;
}

export interface InfrastructureAdminImportItemsDestroyRequest {
    id: number;
}

export interface InfrastructureAdminImportItemsGetConflictsRetrieveRequest {
    id: number;
}

export interface InfrastructureAdminImportItemsGetMatchedInfrastructureListRequest {
    id: number;
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureAdminImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureAdminImportItemsGetSimilarRowsListRequest {
    id: number;
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureAdminImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureAdminImportItemsListRequest {
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureAdminImportItemsListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureAdminImportItemsMergeSimilarRowsCreateRequest {
    id: number;
    mergeSimilarRowRequest: MergeSimilarRowRequest;
}

export interface InfrastructureAdminImportItemsPartialUpdateRequest {
    id: number;
    patchedAdminInfrastructureImportItemRequest?: PatchedAdminInfrastructureImportItemRequest;
}

export interface InfrastructureAdminImportItemsRetrieveRequest {
    id: number;
}

export interface InfrastructureAdminImportItemsUpdateRequest {
    id: number;
    adminInfrastructureImportItemRequest: AdminInfrastructureImportItemRequest;
}

export interface InfrastructureAdminImportListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface InfrastructureAdminImportPartialUpdateRequest {
    id: string;
    patchedAdminInfrastructureImportRequest?: PatchedAdminInfrastructureImportRequest;
}

export interface InfrastructureAdminImportRerunCrossOperatorMatchesCreateRequest {
    id: string;
}

export interface InfrastructureAdminImportRetrieveRequest {
    id: string;
}

export interface InfrastructureAdminImportUpdateRequest {
    id: string;
    adminInfrastructureImportRequest: AdminInfrastructureImportRequest;
}

export interface InfrastructureImportCreateRequest {
    infrastructureImportRequest: InfrastructureImportRequest;
}

export interface InfrastructureImportDestroyRequest {
    id: string;
}

export interface InfrastructureImportGetGeojsonRetrieveRequest {
    id: string;
}

export interface InfrastructureImportItemsCreateRequest {
    infrastructureImportItemCreateRequest: InfrastructureImportItemCreateRequest;
}

export interface InfrastructureImportItemsDestroyRequest {
    id: number;
}

export interface InfrastructureImportItemsGetMatchedInfrastructureListRequest {
    id: number;
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureImportItemsGetSimilarRowsListRequest {
    id: number;
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureImportItemsListRequest {
    crossOperatorConflict?: boolean;
    crossOperatorConflictStatus?: InfrastructureImportItemsListCrossOperatorConflictStatusEnum;
    emptyPlaceholderId?: boolean;
    hasLineDuplicates?: boolean;
    infraType?: string;
    locationNeedsReview?: boolean;
    missingInfraType?: boolean;
    missingParent?: boolean;
    missingParentWithTree?: boolean;
    noLocation?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    parentFor?: number;
    relatedImport?: string;
    search?: string;
    treeFromParentId?: number;
}

export interface InfrastructureImportItemsMergeSimilarRowsCreateRequest {
    id: number;
    mergeSimilarRowRequest: MergeSimilarRowRequest;
}

export interface InfrastructureImportItemsPartialUpdateRequest {
    id: number;
    patchedInfrastructureImportItemRequest?: PatchedInfrastructureImportItemRequest;
}

export interface InfrastructureImportItemsRetrieveRequest {
    id: number;
}

export interface InfrastructureImportItemsUpdateRequest {
    id: number;
    infrastructureImportItemRequest: InfrastructureImportItemRequest;
}

export interface InfrastructureImportListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface InfrastructureImportPartialUpdateRequest {
    id: string;
    patchedInfrastructureImportRequest?: PatchedInfrastructureImportRequest;
}

export interface InfrastructureImportRerunLineDeduplicationMatcherCreateRequest {
    id: string;
}

export interface InfrastructureImportRerunLocationMatcherCreateRequest {
    id: string;
}

export interface InfrastructureImportRetrieveRequest {
    id: string;
}

export interface InfrastructureImportStartProcessingCreateRequest {
    id: string;
}

export interface InfrastructureImportUpdateRequest {
    id: string;
    infrastructureImportRequest: InfrastructureImportRequest;
}

export interface InfrastructureImportUploadUrlCreateRequest {
    id: string;
    infrastructureImportFileUploadRequest: InfrastructureImportFileUploadRequest;
}

export interface InfrastructureImportValidateDataAndSubmitCreateRequest {
    id: string;
}

export interface InfrastructureListListRequest {
    equipmentType?: string;
    infraType?: Array<InfrastructureListListInfraTypeEnum>;
    locationPreset?: Array<string>;
    locationWithin?: string;
    name?: string;
    nearEmission?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    parent?: Array<string>;
    search?: string;
    siteName?: string;
}

export interface InfrastructureListRetrieveRequest {
    id: string;
}

export interface InfrastructurePipelineListRequest {
    locationWithin?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    pipelineProduct?: Array<InfrastructurePipelineListPipelineProductEnum>;
    pipelineSizeMax?: number;
    pipelineSizeMin?: number;
    pipelineType?: Array<InfrastructurePipelineListPipelineTypeEnum>;
}

export interface InfrastructurePipelineRetrieveRequest {
    id: string;
}

export interface InfrastructurePipelineSegmentsListRequest {
    nearPoint?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    pipeline?: string;
}

export interface InfrastructurePipelineSegmentsRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class InfrastructureApi extends runtime.BaseAPI {

    /**
     * Clears the system to import data into production.
     */
    async infrastructureAdminImportApproveAndImportDataCreateRaw(requestParameters: InfrastructureAdminImportApproveAndImportDataCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportApproveAndImportDataCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/approve_and_import_data/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Clears the system to import data into production.
     */
    async infrastructureAdminImportApproveAndImportDataCreate(requestParameters: InfrastructureAdminImportApproveAndImportDataCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportApproveAndImportDataCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportCreateRaw(requestParameters: InfrastructureAdminImportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['adminInfrastructureImportRequest'] == null) {
            throw new runtime.RequiredError(
                'adminInfrastructureImportRequest',
                'Required parameter "adminInfrastructureImportRequest" was null or undefined when calling infrastructureAdminImportCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInfrastructureImportRequestToJSON(requestParameters['adminInfrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportCreate(requestParameters: InfrastructureAdminImportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportDestroyRaw(requestParameters: InfrastructureAdminImportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportDestroy(requestParameters: InfrastructureAdminImportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.infrastructureAdminImportDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsCreateRaw(requestParameters: InfrastructureAdminImportItemsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItemCreate>> {
        if (requestParameters['infrastructureImportItemCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportItemCreateRequest',
                'Required parameter "infrastructureImportItemCreateRequest" was null or undefined when calling infrastructureAdminImportItemsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportItemCreateRequestToJSON(requestParameters['infrastructureImportItemCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemCreateFromJSON(jsonValue));
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsCreate(requestParameters: InfrastructureAdminImportItemsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItemCreate> {
        const response = await this.infrastructureAdminImportItemsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsDestroyRaw(requestParameters: InfrastructureAdminImportItemsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsDestroy(requestParameters: InfrastructureAdminImportItemsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.infrastructureAdminImportItemsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of conflicting infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetConflictsRetrieveRaw(requestParameters: InfrastructureAdminImportItemsGetConflictsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureConflict>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsGetConflictsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/get_conflicts/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureConflictFromJSON(jsonValue));
    }

    /**
     * Returns a list of conflicting infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetConflictsRetrieve(requestParameters: InfrastructureAdminImportItemsGetConflictsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureConflict> {
        const response = await this.infrastructureAdminImportItemsGetConflictsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetMatchedInfrastructureListRaw(requestParameters: InfrastructureAdminImportItemsGetMatchedInfrastructureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InfrastructureMatch>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsGetMatchedInfrastructureList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/get_matched_infrastructure/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfrastructureMatchFromJSON));
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetMatchedInfrastructureList(requestParameters: InfrastructureAdminImportItemsGetMatchedInfrastructureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InfrastructureMatch>> {
        const response = await this.infrastructureAdminImportItemsGetMatchedInfrastructureListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetSimilarRowsListRaw(requestParameters: InfrastructureAdminImportItemsGetSimilarRowsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InfrastructureImportItem>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsGetSimilarRowsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/get_similar_rows/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfrastructureImportItemFromJSON));
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureAdminImportItemsGetSimilarRowsList(requestParameters: InfrastructureAdminImportItemsGetSimilarRowsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InfrastructureImportItem>> {
        const response = await this.infrastructureAdminImportItemsGetSimilarRowsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsListRaw(requestParameters: InfrastructureAdminImportItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAdminInfrastructureImportItemList>> {
        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAdminInfrastructureImportItemListFromJSON(jsonValue));
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsList(requestParameters: InfrastructureAdminImportItemsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAdminInfrastructureImportItemList> {
        const response = await this.infrastructureAdminImportItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs the merge operation.
     */
    async infrastructureAdminImportItemsMergeSimilarRowsCreateRaw(requestParameters: InfrastructureAdminImportItemsMergeSimilarRowsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsMergeSimilarRowsCreate().'
            );
        }

        if (requestParameters['mergeSimilarRowRequest'] == null) {
            throw new runtime.RequiredError(
                'mergeSimilarRowRequest',
                'Required parameter "mergeSimilarRowRequest" was null or undefined when calling infrastructureAdminImportItemsMergeSimilarRowsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/merge_similar_rows/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeSimilarRowRequestToJSON(requestParameters['mergeSimilarRowRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * Performs the merge operation.
     */
    async infrastructureAdminImportItemsMergeSimilarRowsCreate(requestParameters: InfrastructureAdminImportItemsMergeSimilarRowsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImportItem> {
        const response = await this.infrastructureAdminImportItemsMergeSimilarRowsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsPartialUpdateRaw(requestParameters: InfrastructureAdminImportItemsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedAdminInfrastructureImportItemRequestToJSON(requestParameters['patchedAdminInfrastructureImportItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsPartialUpdate(requestParameters: InfrastructureAdminImportItemsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImportItem> {
        const response = await this.infrastructureAdminImportItemsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsRetrieveRaw(requestParameters: InfrastructureAdminImportItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsRetrieve(requestParameters: InfrastructureAdminImportItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImportItem> {
        const response = await this.infrastructureAdminImportItemsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsUpdateRaw(requestParameters: InfrastructureAdminImportItemsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportItemsUpdate().'
            );
        }

        if (requestParameters['adminInfrastructureImportItemRequest'] == null) {
            throw new runtime.RequiredError(
                'adminInfrastructureImportItemRequest',
                'Required parameter "adminInfrastructureImportItemRequest" was null or undefined when calling infrastructureAdminImportItemsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInfrastructureImportItemRequestToJSON(requestParameters['adminInfrastructureImportItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * Extension of `InfrastructureImportItemViewSet` with extra admin editable fields and actions.
     */
    async infrastructureAdminImportItemsUpdate(requestParameters: InfrastructureAdminImportItemsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImportItem> {
        const response = await this.infrastructureAdminImportItemsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportListRaw(requestParameters: InfrastructureAdminImportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAdminInfrastructureImportList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAdminInfrastructureImportListFromJSON(jsonValue));
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportList(requestParameters: InfrastructureAdminImportListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAdminInfrastructureImportList> {
        const response = await this.infrastructureAdminImportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportPartialUpdateRaw(requestParameters: InfrastructureAdminImportPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedAdminInfrastructureImportRequestToJSON(requestParameters['patchedAdminInfrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportPartialUpdate(requestParameters: InfrastructureAdminImportPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reruns the cross operator matching checks.
     */
    async infrastructureAdminImportRerunCrossOperatorMatchesCreateRaw(requestParameters: InfrastructureAdminImportRerunCrossOperatorMatchesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportRerunCrossOperatorMatchesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/rerun_cross_operator_matches/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Reruns the cross operator matching checks.
     */
    async infrastructureAdminImportRerunCrossOperatorMatchesCreate(requestParameters: InfrastructureAdminImportRerunCrossOperatorMatchesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportRerunCrossOperatorMatchesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportRetrieveRaw(requestParameters: InfrastructureAdminImportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportRetrieve(requestParameters: InfrastructureAdminImportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportUpdateRaw(requestParameters: InfrastructureAdminImportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureAdminImportUpdate().'
            );
        }

        if (requestParameters['adminInfrastructureImportRequest'] == null) {
            throw new runtime.RequiredError(
                'adminInfrastructureImportRequest',
                'Required parameter "adminInfrastructureImportRequest" was null or undefined when calling infrastructureAdminImportUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/admin-import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInfrastructureImportRequestToJSON(requestParameters['adminInfrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Admin-only viewset used to edit infrastructure imports.
     */
    async infrastructureAdminImportUpdate(requestParameters: InfrastructureAdminImportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInfrastructureImport> {
        const response = await this.infrastructureAdminImportUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportCreateRaw(requestParameters: InfrastructureImportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['infrastructureImportRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportRequest',
                'Required parameter "infrastructureImportRequest" was null or undefined when calling infrastructureImportCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportRequestToJSON(requestParameters['infrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportCreate(requestParameters: InfrastructureImportCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportDestroyRaw(requestParameters: InfrastructureImportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportDestroy(requestParameters: InfrastructureImportDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.infrastructureImportDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a GeoJson Object with all the items in the import.
     */
    async infrastructureImportGetGeojsonRetrieveRaw(requestParameters: InfrastructureImportGetGeojsonRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportGetGeojsonRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/get_geojson/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a GeoJson Object with all the items in the import.
     */
    async infrastructureImportGetGeojsonRetrieve(requestParameters: InfrastructureImportGetGeojsonRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.infrastructureImportGetGeojsonRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsCreateRaw(requestParameters: InfrastructureImportItemsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItemCreate>> {
        if (requestParameters['infrastructureImportItemCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportItemCreateRequest',
                'Required parameter "infrastructureImportItemCreateRequest" was null or undefined when calling infrastructureImportItemsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportItemCreateRequestToJSON(requestParameters['infrastructureImportItemCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemCreateFromJSON(jsonValue));
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsCreate(requestParameters: InfrastructureImportItemsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItemCreate> {
        const response = await this.infrastructureImportItemsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsDestroyRaw(requestParameters: InfrastructureImportItemsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsDestroy(requestParameters: InfrastructureImportItemsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.infrastructureImportItemsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureImportItemsGetMatchedInfrastructureListRaw(requestParameters: InfrastructureImportItemsGetMatchedInfrastructureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InfrastructureMatch>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsGetMatchedInfrastructureList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/get_matched_infrastructure/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfrastructureMatchFromJSON));
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureImportItemsGetMatchedInfrastructureList(requestParameters: InfrastructureImportItemsGetMatchedInfrastructureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InfrastructureMatch>> {
        const response = await this.infrastructureImportItemsGetMatchedInfrastructureListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureImportItemsGetSimilarRowsListRaw(requestParameters: InfrastructureImportItemsGetSimilarRowsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InfrastructureImportItem>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsGetSimilarRowsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/get_similar_rows/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfrastructureImportItemFromJSON));
    }

    /**
     * Returns a list of matched infrastructure alongside the distance to them.
     */
    async infrastructureImportItemsGetSimilarRowsList(requestParameters: InfrastructureImportItemsGetSimilarRowsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InfrastructureImportItem>> {
        const response = await this.infrastructureImportItemsGetSimilarRowsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsListRaw(requestParameters: InfrastructureImportItemsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInfrastructureImportItemList>> {
        const queryParameters: any = {};

        if (requestParameters['crossOperatorConflict'] != null) {
            queryParameters['cross_operator_conflict'] = requestParameters['crossOperatorConflict'];
        }

        if (requestParameters['crossOperatorConflictStatus'] != null) {
            queryParameters['cross_operator_conflict_status'] = requestParameters['crossOperatorConflictStatus'];
        }

        if (requestParameters['emptyPlaceholderId'] != null) {
            queryParameters['empty_placeholder_id'] = requestParameters['emptyPlaceholderId'];
        }

        if (requestParameters['hasLineDuplicates'] != null) {
            queryParameters['has_line_duplicates'] = requestParameters['hasLineDuplicates'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationNeedsReview'] != null) {
            queryParameters['location_needs_review'] = requestParameters['locationNeedsReview'];
        }

        if (requestParameters['missingInfraType'] != null) {
            queryParameters['missing_infra_type'] = requestParameters['missingInfraType'];
        }

        if (requestParameters['missingParent'] != null) {
            queryParameters['missing_parent'] = requestParameters['missingParent'];
        }

        if (requestParameters['missingParentWithTree'] != null) {
            queryParameters['missing_parent_with_tree'] = requestParameters['missingParentWithTree'];
        }

        if (requestParameters['noLocation'] != null) {
            queryParameters['no_location'] = requestParameters['noLocation'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['parentFor'] != null) {
            queryParameters['parent_for'] = requestParameters['parentFor'];
        }

        if (requestParameters['relatedImport'] != null) {
            queryParameters['related_import'] = requestParameters['relatedImport'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['treeFromParentId'] != null) {
            queryParameters['tree_from_parent_id'] = requestParameters['treeFromParentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInfrastructureImportItemListFromJSON(jsonValue));
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsList(requestParameters: InfrastructureImportItemsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInfrastructureImportItemList> {
        const response = await this.infrastructureImportItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs the merge operation.
     */
    async infrastructureImportItemsMergeSimilarRowsCreateRaw(requestParameters: InfrastructureImportItemsMergeSimilarRowsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsMergeSimilarRowsCreate().'
            );
        }

        if (requestParameters['mergeSimilarRowRequest'] == null) {
            throw new runtime.RequiredError(
                'mergeSimilarRowRequest',
                'Required parameter "mergeSimilarRowRequest" was null or undefined when calling infrastructureImportItemsMergeSimilarRowsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/merge_similar_rows/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeSimilarRowRequestToJSON(requestParameters['mergeSimilarRowRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * Performs the merge operation.
     */
    async infrastructureImportItemsMergeSimilarRowsCreate(requestParameters: InfrastructureImportItemsMergeSimilarRowsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItem> {
        const response = await this.infrastructureImportItemsMergeSimilarRowsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsPartialUpdateRaw(requestParameters: InfrastructureImportItemsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedInfrastructureImportItemRequestToJSON(requestParameters['patchedInfrastructureImportItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsPartialUpdate(requestParameters: InfrastructureImportItemsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItem> {
        const response = await this.infrastructureImportItemsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsRetrieveRaw(requestParameters: InfrastructureImportItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsRetrieve(requestParameters: InfrastructureImportItemsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItem> {
        const response = await this.infrastructureImportItemsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsUpdateRaw(requestParameters: InfrastructureImportItemsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportItem>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportItemsUpdate().'
            );
        }

        if (requestParameters['infrastructureImportItemRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportItemRequest',
                'Required parameter "infrastructureImportItemRequest" was null or undefined when calling infrastructureImportItemsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import-items/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportItemRequestToJSON(requestParameters['infrastructureImportItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportItemFromJSON(jsonValue));
    }

    /**
     * A viewset to edit infrastructure import item information
     */
    async infrastructureImportItemsUpdate(requestParameters: InfrastructureImportItemsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportItem> {
        const response = await this.infrastructureImportItemsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportListRaw(requestParameters: InfrastructureImportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInfrastructureImportList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInfrastructureImportListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportList(requestParameters: InfrastructureImportListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInfrastructureImportList> {
        const response = await this.infrastructureImportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportPartialUpdateRaw(requestParameters: InfrastructureImportPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedInfrastructureImportRequestToJSON(requestParameters['patchedInfrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportPartialUpdate(requestParameters: InfrastructureImportPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reruns the line deduplication matching checks.
     */
    async infrastructureImportRerunLineDeduplicationMatcherCreateRaw(requestParameters: InfrastructureImportRerunLineDeduplicationMatcherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportRerunLineDeduplicationMatcherCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/rerun_line_deduplication_matcher/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Reruns the line deduplication matching checks.
     */
    async infrastructureImportRerunLineDeduplicationMatcherCreate(requestParameters: InfrastructureImportRerunLineDeduplicationMatcherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportRerunLineDeduplicationMatcherCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reruns the operator matching checks.
     */
    async infrastructureImportRerunLocationMatcherCreateRaw(requestParameters: InfrastructureImportRerunLocationMatcherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportRerunLocationMatcherCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/rerun_location_matcher/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Reruns the operator matching checks.
     */
    async infrastructureImportRerunLocationMatcherCreate(requestParameters: InfrastructureImportRerunLocationMatcherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportRerunLocationMatcherCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportRetrieveRaw(requestParameters: InfrastructureImportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportRetrieve(requestParameters: InfrastructureImportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Clears the system to start processing the uploaded data.  Used to avoid a race condition when uploading lots of files.
     */
    async infrastructureImportStartProcessingCreateRaw(requestParameters: InfrastructureImportStartProcessingCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportStartProcessingCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/start_processing/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Clears the system to start processing the uploaded data.  Used to avoid a race condition when uploading lots of files.
     */
    async infrastructureImportStartProcessingCreate(requestParameters: InfrastructureImportStartProcessingCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportStartProcessingCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportUpdateRaw(requestParameters: InfrastructureImportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportUpdate().'
            );
        }

        if (requestParameters['infrastructureImportRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportRequest',
                'Required parameter "infrastructureImportRequest" was null or undefined when calling infrastructureImportUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportRequestToJSON(requestParameters['infrastructureImportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure import information
     */
    async infrastructureImportUpdate(requestParameters: InfrastructureImportUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async infrastructureImportUploadUrlCreateRaw(requestParameters: InfrastructureImportUploadUrlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImportPresignedUploadUrl>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportUploadUrlCreate().'
            );
        }

        if (requestParameters['infrastructureImportFileUploadRequest'] == null) {
            throw new runtime.RequiredError(
                'infrastructureImportFileUploadRequest',
                'Required parameter "infrastructureImportFileUploadRequest" was null or undefined when calling infrastructureImportUploadUrlCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/upload_url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfrastructureImportFileUploadRequestToJSON(requestParameters['infrastructureImportFileUploadRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportPresignedUploadUrlFromJSON(jsonValue));
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async infrastructureImportUploadUrlCreate(requestParameters: InfrastructureImportUploadUrlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImportPresignedUploadUrl> {
        const response = await this.infrastructureImportUploadUrlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validates the data from the table view and then moves the process to the next step.  # TODO: move permissions from actions to separate permissions file.
     */
    async infrastructureImportValidateDataAndSubmitCreateRaw(requestParameters: InfrastructureImportValidateDataAndSubmitCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureImport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureImportValidateDataAndSubmitCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/import/{id}/validate_data_and_submit/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureImportFromJSON(jsonValue));
    }

    /**
     * Validates the data from the table view and then moves the process to the next step.  # TODO: move permissions from actions to separate permissions file.
     */
    async infrastructureImportValidateDataAndSubmitCreate(requestParameters: InfrastructureImportValidateDataAndSubmitCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureImport> {
        const response = await this.infrastructureImportValidateDataAndSubmitCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async infrastructureListListRaw(requestParameters: InfrastructureListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInfrastructureList>> {
        const queryParameters: any = {};

        if (requestParameters['equipmentType'] != null) {
            queryParameters['equipment_type'] = requestParameters['equipmentType'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nearEmission'] != null) {
            queryParameters['near_emission'] = requestParameters['nearEmission'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['parent'] != null) {
            queryParameters['parent'] = requestParameters['parent'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['siteName'] != null) {
            queryParameters['site_name'] = requestParameters['siteName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInfrastructureListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async infrastructureListList(requestParameters: InfrastructureListListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInfrastructureList> {
        const response = await this.infrastructureListListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async infrastructureListRetrieveRaw(requestParameters: InfrastructureListRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Infrastructure>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructureListRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async infrastructureListRetrieve(requestParameters: InfrastructureListRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Infrastructure> {
        const response = await this.infrastructureListRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async infrastructurePipelineListRaw(requestParameters: InfrastructurePipelineListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPipelineList>> {
        const queryParameters: any = {};

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['pipelineProduct'] != null) {
            queryParameters['pipeline_product'] = requestParameters['pipelineProduct'];
        }

        if (requestParameters['pipelineSizeMax'] != null) {
            queryParameters['pipeline_size_max'] = requestParameters['pipelineSizeMax'];
        }

        if (requestParameters['pipelineSizeMin'] != null) {
            queryParameters['pipeline_size_min'] = requestParameters['pipelineSizeMin'];
        }

        if (requestParameters['pipelineType'] != null) {
            queryParameters['pipeline_type'] = requestParameters['pipelineType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/pipeline/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPipelineListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async infrastructurePipelineList(requestParameters: InfrastructurePipelineListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPipelineList> {
        const response = await this.infrastructurePipelineListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async infrastructurePipelineRetrieveRaw(requestParameters: InfrastructurePipelineRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pipeline>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructurePipelineRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/pipeline/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async infrastructurePipelineRetrieve(requestParameters: InfrastructurePipelineRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pipeline> {
        const response = await this.infrastructurePipelineRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline segment information
     */
    async infrastructurePipelineSegmentsListRaw(requestParameters: InfrastructurePipelineSegmentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPipelineSegmentList>> {
        const queryParameters: any = {};

        if (requestParameters['nearPoint'] != null) {
            queryParameters['near_point'] = requestParameters['nearPoint'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['pipeline'] != null) {
            queryParameters['pipeline'] = requestParameters['pipeline'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/pipeline-segments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPipelineSegmentListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline segment information
     */
    async infrastructurePipelineSegmentsList(requestParameters: InfrastructurePipelineSegmentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPipelineSegmentList> {
        const response = await this.infrastructurePipelineSegmentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline segment information
     */
    async infrastructurePipelineSegmentsRetrieveRaw(requestParameters: InfrastructurePipelineSegmentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSegment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling infrastructurePipelineSegmentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // KnoxTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/infrastructure/pipeline-segments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSegmentFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline segment information
     */
    async infrastructurePipelineSegmentsRetrieve(requestParameters: InfrastructurePipelineSegmentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSegment> {
        const response = await this.infrastructurePipelineSegmentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const InfrastructureAdminImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureAdminImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum = typeof InfrastructureAdminImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureAdminImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureAdminImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureAdminImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum = typeof InfrastructureAdminImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureAdminImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureAdminImportItemsListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureAdminImportItemsListCrossOperatorConflictStatusEnum = typeof InfrastructureAdminImportItemsListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureAdminImportItemsListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum = typeof InfrastructureImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureImportItemsGetMatchedInfrastructureListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum = typeof InfrastructureImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureImportItemsGetSimilarRowsListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureImportItemsListCrossOperatorConflictStatusEnum = {
    AllowAndCreate: 'ALLOW_AND_CREATE',
    BlockAndIgnore: 'BLOCK_AND_IGNORE',
    Conflict: 'CONFLICT',
    NearMatch: 'NEAR_MATCH',
    NoConflict: 'NO_CONFLICT'
} as const;
export type InfrastructureImportItemsListCrossOperatorConflictStatusEnum = typeof InfrastructureImportItemsListCrossOperatorConflictStatusEnum[keyof typeof InfrastructureImportItemsListCrossOperatorConflictStatusEnum];
/**
 * @export
 */
export const InfrastructureListListInfraTypeEnum = {
    Equipment: 'EQUIPMENT',
    EquipmentComponent: 'EQUIPMENT_COMPONENT',
    EquipmentGroup: 'EQUIPMENT_GROUP',
    Pipeline: 'PIPELINE',
    Site: 'SITE'
} as const;
export type InfrastructureListListInfraTypeEnum = typeof InfrastructureListListInfraTypeEnum[keyof typeof InfrastructureListListInfraTypeEnum];
/**
 * @export
 */
export const InfrastructurePipelineListPipelineProductEnum = {
    Condensate: 'CONDENSATE',
    NaturalGas: 'NATURAL_GAS',
    Ngl: 'NGL',
    Oil: 'OIL',
    Other: 'OTHER',
    Water: 'WATER'
} as const;
export type InfrastructurePipelineListPipelineProductEnum = typeof InfrastructurePipelineListPipelineProductEnum[keyof typeof InfrastructurePipelineListPipelineProductEnum];
/**
 * @export
 */
export const InfrastructurePipelineListPipelineTypeEnum = {
    Distribution: 'DISTRIBUTION',
    Gathering: 'GATHERING',
    Other: 'OTHER',
    Transmission: 'TRANSMISSION'
} as const;
export type InfrastructurePipelineListPipelineTypeEnum = typeof InfrastructurePipelineListPipelineTypeEnum[keyof typeof InfrastructurePipelineListPipelineTypeEnum];
